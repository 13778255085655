import { DetailsList, DetailsListLayoutMode, IColumn, IDetailsRowProps, ScrollablePane, Selection, SelectionMode, Sticky, StickyPositionType } from "@fluentui/react";
import { CSSProperties, useEffect, useMemo, useState } from "react";
import "./Table.scss";

type Props = {
    columns: IColumn[];
    items: any[];
    selectionMode?: SelectionMode;
    isInfinityScroll?: boolean;
    wrapperStyle?: CSSProperties | undefined;

    onSelectionChange?: any;
    onSortOptionChange?: any;
    onLoadMore?: any;
}

export function Table(props: Props) {
    const {
        columns: columnProps,
        items: itemProps,
        onSelectionChange,
        onSortOptionChange,
        selectionMode,
        isInfinityScroll,
        onLoadMore,
        wrapperStyle
    } = props;

    const [items, setItems] = useState(itemProps);
    const [columns, setColumns] = useState(columnProps);

    useEffect(() => {
        let temporaryItems = [...items];
        if (isInfinityScroll && onLoadMore) {
            temporaryItems.push(null);
        }
        setItems(itemProps);
    }, [itemProps, isInfinityScroll])

    useEffect(() => {
        const columns = columnProps.map(column => {
            return {
                ...column,
                onColumnClick: (e, column) => onColumnClick(column)
            };
        }) as IColumn[];

        setColumns(columns);
    }, [columnProps])

    const selection: Selection = useMemo(() => {
        return new Selection({
            onSelectionChanged: () => {
                let items = [];
                if (selection.count > 0) {
                    items = selection.getSelection() as any[];
                }
                onSelectionChange(items);
            },
        });
    }, [])

    const onColumnClick = (column: IColumn) => {
        const newColumns: IColumn[] = columns.filter(m => m);
        if (!onSortOptionChange) {
            return;
        }
        let sortColumn = {};

        newColumns.forEach((newCol: IColumn) => {
            if (newCol.key === column.key) {
                newCol.isSortedDescending = !column.isSortedDescending;
                newCol.isSorted = true;
                sortColumn = {
                    ...newCol
                };
            } else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        onSortOptionChange(sortColumn, newColumns);
    }

    const onRenderMissingItem = (index?: number, rowProps?: IDetailsRowProps) => {
        if (isInfinityScroll && onLoadMore) {
            onLoadMore();
        }
        return <></>
    }

    const onRenderDetailsHeader = (props: any, defaultRender?: any) => {
        return <>
            <Sticky stickyPosition={StickyPositionType.Header} stickyBackgroundColor="transparent">
                <div>{defaultRender!(props)}</div>
            </Sticky>
        </>
    }

    return (
        <div style={{ height: "300px", ...wrapperStyle, position: "relative" }}>
            <ScrollablePane>
                <DetailsList
                    items={items}
                    selection={selection}
                    columns={columns}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                    selectionMode={selectionMode}
                    onRenderMissingItem={onRenderMissingItem}
                    layoutMode={DetailsListLayoutMode.justified}/>
            </ScrollablePane>
        </div>

    )
}