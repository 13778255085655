import { DefaultButton, Dropdown, IDropdownOption, PrimaryButton, TextField } from '@fluentui/react';
import { LanguageEnum, LanguageHelper } from '@onix/common';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PhoneNumber } from '../../../../components/PhoneNumber/PhoneNumber';
import { removeWhiteSpace } from '../../../../core/utils/stringHelper';
import { UserAccount } from '../../../../models/user-account.model';
import { updateInformation } from '../../../../services/account.service';
import { getSupportedLanguages } from '../../../../services/common.service';
import { showErrorDialogWithResponse } from '../../../../services/dialog.service';
import { appDispatch } from '../../../../states/store';
import { UserActions, userSelector } from '../../../../states/user/userSlice';
import './ProfileInfo.scss';

const defaultFormValue = {
  fullName: '',
  userFirstName: '',
  userLastName: '',
  userCellPhone: '',
  languageId: LanguageEnum.English,
};

type Props = {
  openModalChange?: any;
};

export function ProfileInfo(props: Props) {
  const dispatch = appDispatch;
  const [translate, i18n] = useTranslation();
  const [languageOptions, setLanguageOptions] = useState([] as IDropdownOption[]);

  const user = useSelector(userSelector);

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    setError,
    clearErrors,
    control,
    formState: { errors },
  } = useForm({ defaultValues: defaultFormValue, mode: 'onTouched' });

  useEffect(() => {
    const currentLanguages = getSupportedLanguages();
    const mappingLanguages = currentLanguages.map((language) => {
      return {
        key: language.id,
        text: language.name,
        title: language.name,
      } as IDropdownOption;
    });
    setLanguageOptions(mappingLanguages);
  }, []);

  useEffect(() => {
    const keys = Object.keys(defaultFormValue);
    keys.forEach((key) => {
      setValue(key as any, (user as any)[key] as UserAccount);
    });
  }, [user, setValue]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'userFirstName' || name === 'userLastName') {
        const firstName = watch('userFirstName');
        const lastName = watch('userLastName');
        setValue('fullName', `${firstName} ${lastName}`);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue]);

  const phoneNumberChange = (data: any) => {
    if (data.nationalPhone === '' || data.country === '') {
      setValue('userCellPhone', '');
      clearErrors('userCellPhone');
      if (data.nationalPhone === '') {
        return;
      }
    }
    const isValidPhone = data?.isValidPhone ?? false;
    if (!isValidPhone) {
      setError('userCellPhone', { type: 'invalidPhoneNumber' });
      setValue('userCellPhone', '');
      return;
    }
    clearErrors('userCellPhone');
    const userCellPhone = data.phoneNumber;
    setValue('userCellPhone', userCellPhone);
  };

  const textFieldChange = useCallback(
    (value: string | undefined, fieldName: any) => {
      const valueCleaned = removeWhiteSpace(value);
      if (valueCleaned.trim().length === 0) {
        setError(fieldName, { type: 'required' });
      }
      setValue(fieldName, valueCleaned);
    },
    [setValue]
  );

  const closeModal = useCallback(() => {
    if (props?.openModalChange) {
      props.openModalChange(false);
      reset();
    }
  }, [props, reset]);

  const submitForm = async (data: any) => {
    try {
      const request = {
        ...data,
        userFirstName: data.userFirstName.trim(),
        userLastName: data.userLastName.trim(),
      } as UserAccount;

      const userAccountResponse = await updateInformation(request);
      dispatch(UserActions.updateInformation(userAccountResponse));
      props.openModalChange(false);
      const language = LanguageHelper.getLanguageCode(userAccountResponse.languageId);
      i18n.changeLanguage(language);
    } catch (err) {
      showErrorDialogWithResponse(err);
    }
  };

  return (
    <form className="form-default profile-info-container" onSubmit={handleSubmit(submitForm)}>
      <div className="row">
        <div className="controls">
          <span>{translate('CommonResource.Name')}:</span>
          <Controller name="fullName" control={control} render={({ field }) => <TextField disabled {...(field as any)} />} />
        </div>
      </div>
      <div className="row">
        <div className="controls">
          <span>{translate('CommonResource.FirstName')}:</span>
          <Controller
            name="userFirstName"
            control={control}
            render={({ field }) => <TextField {...(field as any)} />}
            rules={{ required: true, maxLength: 50, onChange: (e) => textFieldChange(e?.target?.value, 'userFirstName') }}
          />
        </div>
        <div className="errors">
          {errors?.userFirstName?.type === 'required' && <span>{translate('CaptionResource.Required')}</span>}
          {errors?.userFirstName?.type === 'maxLength' && <span>{translate('CaptionResource.OverMaxLength')}</span>}
        </div>
      </div>
      <div className="row">
        <div className="controls">
          <span>{translate('CommonResource.LastName')}:</span>
          <Controller
            name="userLastName"
            control={control}
            render={({ field }) => <TextField {...(field as any)} />}
            rules={{ required: true, maxLength: 50, onChange: (e) => textFieldChange(e?.target?.value, 'userLastName') }}
          />
        </div>
        <div className="errors">
          {errors?.userLastName?.type === 'required' && <span>{translate('CaptionResource.Required')}</span>}
          {errors?.userLastName?.type === 'maxLength' && <span>{translate('CaptionResource.OverMaxLength')}</span>}
        </div>
      </div>
      <div className="row">
        <div className="controls phone-number">
          <span>{translate('CaptionResource.MobilePhoneMA')}:</span>
          <PhoneNumber phoneNumber={user?.userCellPhone ?? ''} onChange={phoneNumberChange}></PhoneNumber>
        </div>
        <div className="errors">{errors?.userCellPhone && <span>{translate('CaptionResource.InvalidPhoneNumberMA')}</span>}</div>
      </div>
      <div className="row">
        <div className="controls">
          <span>{translate('CaptionResource.PreferredLanguage')}:</span>
          <Controller
            name="languageId"
            control={control}
            render={({ field }) => (
              <Dropdown
                {...field}
                onChange={(e, option) => {
                  if (option) {
                    field.onChange(option?.key as any);
                  }
                }}
                selectedKey={field.value}
                options={languageOptions}
              />
            )}
          />
        </div>
      </div>
      <div className="row-footer modal-footer">
        <PrimaryButton type="submit">{translate('CommonResource.Save')}</PrimaryButton>
        <DefaultButton type="button" onClick={closeModal}>
          {translate('CommonResource.Cancel')}
        </DefaultButton>
      </div>
    </form>
  );
}
