import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { AppReducer } from "./app/appSlice";
import { ExpressReducer } from "./express/expressSlice";
import { ModalReducer } from "./modal/modalSlice";
import { OrganizationReducer } from "./organization/organizationSlice";
import { UserReducer } from "./user/userSlice";

export type RootState = ReturnType<typeof store.getState>

export const store = configureStore({
    reducer: {
        app: AppReducer,
        user: UserReducer,
        modal: ModalReducer,
        organization: OrganizationReducer,
        express: ExpressReducer
    },
    devTools: process.env.NODE_ENV !== 'production',
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false
    })
})

export const appDispatch = store.dispatch;