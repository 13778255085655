import { HttpClient } from "@onix/common";
import { SettingKeyConstants } from "../core/constants/setting-key.constants";
import { AppActions } from "../states/app/appSlice";
import { appDispatch } from "../states/store";
import { generateLocalKeyByName } from "./common.service";
import { StatusCodes } from 'http-status-codes';
import { clearToken, login } from "./authentication.service";

const dispatch = appDispatch;
const appActions = AppActions;

export const getAsync = (url: string, request: any = {},
    isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {
    detectShowLoading(isLoading);
    return HttpClient.getAsync(url, request, headers)
    .catch(async (err: any) => {
        return handleError(err);
    })
    .finally(() => {
        detectHiddenLoading(isLoading);
    });
}

export const postAsync = (url: string, request: any = {},
    isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {
    detectShowLoading(isLoading);
    return HttpClient.postAsync(url, request, headers)
    .catch(async (err: any) => {
        return handleError(err);
    })
    .finally(() => {
        detectHiddenLoading(isLoading);
    });
}

export const putAsync = (url: string, request: any = {},
    isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {
    detectShowLoading(isLoading);
    return HttpClient.putAsync(url, request, headers)
    .catch(async (err: any) => {
        return handleError(err);
    })
    .finally(() => {
        detectHiddenLoading(isLoading);
    });
}

export const deleteAsync = (url: string,
    isLoading: boolean = true, headers: any = getAuthorizationHeaders()) => {
    detectShowLoading(isLoading);
    return HttpClient.deleteAsync(url, headers)
    .catch(async (err: any) => {
        return await handleError(err);
    })
    .finally(() => {
        detectHiddenLoading(isLoading);
    });
}

function getAuthorizationHeaders() {
    const token = getToken();
    return {
        Authorization: `Bearer ${token}`
    }
}

const detectShowLoading =  (isLoading: boolean) => {
    if (isLoading) {
        dispatch(appActions.showLoading());
    }
}

const detectHiddenLoading = (isLoading: boolean) => {
    if (isLoading) {
        dispatch(appActions.hiddenLoading());
    }
}

const getToken = () => {
    return (
      sessionStorage.getItem(generateLocalKeyByName(SettingKeyConstants.AccessToken)) ||
      localStorage.getItem(generateLocalKeyByName(SettingKeyConstants.AccessToken)) ||
      ''
    );
}

const handleError = async (response: any) => {
    if (response?.status === StatusCodes.UNAUTHORIZED) {
        clearToken();
        await login();
        return;
    }

    if (response?.data) {
        return Promise.reject(response.data)
    }
    return Promise.reject(response);
}