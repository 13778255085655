import "./ContainerLayout.scss";
import { ReactElement } from "react"
import { Outlet } from "react-router-dom";

type Props = {
    children?: string | ReactElement;
}

export function ContainerLayout(props: Props) {
    return (
        <div className="container-layout">
            { props?.children || <Outlet/> }
        </div>
    )
}