import { ActionButton, Icon, Text } from '@fluentui/react';
import { Card } from '@onix/common';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DialogTypeEnum } from '../../../core/constants/enums/dialog-type.enum';
import { Organization } from '../../../models/organization.model';
import { logout } from '../../../services/authentication.service';
import { getCurrentLanguage } from '../../../services/common.service';
import { showConfirmDialog, showErrorDialog } from '../../../services/dialog.service';
import { getExpressOrganization } from '../../../services/organization.service';

export default function ExpressList() {
  const [translate] = useTranslation();
  const [orgs, setOrgs] = useState<Organization[]>([]);
  const getOrgs = useCallback(async () => {
    setOrgs(await getExpressOrganization());
  }, []);

  useEffect(() => {
    getOrgs();
    return () => {
      // This code runs when component is unmounted
      setOrgs([]);
    };
  }, [getOrgs]);

  const onExpressClick = (organization: Organization) => {
    if (organization.expressLogonUrl) {
      showConfirmDialog({
        dialogContentProps: {
          title: translate('Home.LogOffAndGoToWebsite'),
          showCloseButton: true,
          subText: translate('Home.GoToWebsiteMessage', { 0: organization.companyOwnerName }),
        },
        onAccept: async () => {
          window.open(organization.expressLogonUrl);
          await logout(getCurrentLanguage());
        },
      });
    } else {
      showErrorDialog(
        '',
        translate('Home.WebsiteNotFoundMessage', { 0: organization.companyOwnerName, 1: organization.companyName }),
        DialogTypeEnum.CustomTitle,
        translate('Home.WebsiteNotFound')
      );
    }
  };
  return (
    <>
      {orgs.length === 0 ? (
        ''
      ) : (
        <>
          <Text variant="xLarge" className="action-title">
            Onix Express
          </Text>
          <Text className="action-description">{translate('Home.ExpressAccessText')}</Text>

          {orgs.map((yourAccess) => {
            return (
              <Card key={yourAccess.contactId}>
                <div className="card-content">
                  <Icon iconName="Documentation" styles={{ root: { fontSize: '24px' } }} />
                  <div className="details">
                    <span className="name">
                      {yourAccess?.companyOwnerName} - {yourAccess?.companyName}
                    </span>
                  </div>
                  {yourAccess.expressLogonUrl && (
                    <div className="right-controls">
                      <ActionButton onClick={() => onExpressClick(yourAccess)}>{translate('Home.CompanyWebsite')}</ActionButton>
                    </div>
                  )}
                </div>
              </Card>
            );
          })}
        </>
      )}
    </>
  );
}
