import { ReactElement } from "react";
import "./Section.scss";
type Props = {
    title?: string | ReactElement;
    description?: string | ReactElement;
    children?: string | ReactElement;
    className?: string;
}
export function Section(props: Props) {
    return (
        <div className={"section " + props?.className}>
            <div className="description">
                <span className="title-section">
                    {props?.title}
                </span>
                <span>
                    {props?.description}
                </span>
            </div>
            <div className="section-body">
                {props?.children}
            </div>
        </div>
    )
}