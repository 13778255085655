export const defaultTheme = {
  palette: {
    themePrimary: '#069a8e',
    themeLighterAlt: '#f1fbfa',
    themeLighter: '#caefec',
    themeLight: '#a0e0db',
    themeTertiary: '#52c2b8',
    themeSecondary: '#1aa59a',
    themeDarkAlt: '#068a7f',
    themeDark: '#05746b',
    themeDarker: '#03564f',
    neutralLighterAlt: '#faf9f8',
    neutralLighter: '#f3f2f1',
    neutralLight: '#edebe9',
    neutralQuaternaryAlt: '#e1dfdd',
    neutralQuaternary: '#d0d0d0',
    neutralTertiaryAlt: '#c8c6c4',
    neutralTertiary: '#a19f9d',
    neutralSecondary: '#605e5c',
    neutralSecondaryAlt: '#8a8886',
    neutralPrimaryAlt: '#3b3a39',
    neutralPrimary: '#323130',
    neutralDark: '#201f1e',
    black: '#000000',
    white: '#ffffff',
  },
};
