import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { ThemeProvider } from '@fluentui/react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './assets/css/_fluent.scss';
import './assets/css/_global.scss';
import { defaultTheme } from './assets/themes/default';
import { ApiConstants } from './core/constants/api.constants';
import languageConfiguration from './core/utils/languageHelper';
import reportWebVitals from './reportWebVitals';
import { store } from './states/store';

languageConfiguration(ApiConstants.MyAccount.Setting.GetLanguages);
initializeIcons();

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <ThemeProvider applyTo="body" theme={defaultTheme}>
      <App />
    </ThemeProvider>
  </Provider>
);
reportWebVitals();
