import { MutableRefObject, useEffect } from "react";

export const useEnterPress = (ref: MutableRefObject<any>, callBackFunction: Function) => {
    useEffect(() => {
        if (ref) {
            ref.current.onkeydown = (keyboardEvent: KeyboardEvent) => {
                if (keyboardEvent.key === "Enter") {
                    callBackFunction();
                }
            }
        }
    }, [ref])
}