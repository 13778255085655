import { IconButton } from "@fluentui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../core/constants/routes.constants";
import "./LeftMenu.scss";
 
export default function LeftMenu() {
    const [isMenuOpen, setIsMenuOpen] = useState(true);
    const [menuSelected, setMenuSelected] = useState('overview');

    const [translate] = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        const pathName = window.location.pathname !== '/' ?  window.location.pathname : `/${RouteConstants.Overview}`
        const rootPathNameSplitted = pathName.split('/')[1];
        setMenuSelected(rootPathNameSplitted);
    }, [window.location.pathname]);

    const collapseMenu = () => {
        const value = !isMenuOpen;
        setIsMenuOpen(value);
    }

    const menuClicked = (url: string) => {
        navigate(url);
    }

    const menuItemGenerate = useCallback((url: string, iconName: string, displayName: string) => {
        return (
            <div
                className={"menu-item selection" + (url === menuSelected ? " menu-item-selected" : "")}
                onClick={() => menuClicked(url)}>
                    <IconButton allowDisabledFocus iconProps={{iconName}} className="menu-item-icon"></IconButton>
                    <span>{displayName}</span>
            </div>
        )
    }, [menuSelected])

    return (
        <div className={"home-left-menu" + (isMenuOpen ? " open" : "")}>
            <div className="menu-item">
                <IconButton allowDisabledFocus iconProps={{iconName: 'GlobalNavButton', iconType: 1}} className="menu-item-icon collapse" onClick={collapseMenu}></IconButton>
            </div>

            {menuItemGenerate(RouteConstants.Overview, 'Contact', translate('CaptionResource.Overview'))}
            {menuItemGenerate(RouteConstants.Information.Root, 'EditContact', translate('CaptionResource.YourInfo'))}
            {menuItemGenerate(RouteConstants.Organization, 'CompanyDirectory', translate('CaptionResource.Organization'))}
            {menuItemGenerate(RouteConstants.ExpressPortal, 'Documentation', translate('CaptionResource.ExpressPortal'))}
        </div>
    )
}