import { useEffect } from "react";
import { logout } from "../../services/authentication.service";
import { getCurrentLanguage } from "../../services/common.service";

export default function Logout() {
    useEffect(() => {
        async function logoutFunction() {
            await logout(getCurrentLanguage());
        }
        logoutFunction();
    }, [])
    
    return (
        <div></div>
    )
}