import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import LoginLayout from './components/LoginLayout/LoginLayout';
import Login from './pages/Authentication/Login';
import Logout from './pages/Authentication/Logout';
import HomeLayout from './components/HomeLayout/HomeLayout';
import Authentication from './pages/Authentication/Authentication';
import AuthenticationCallBack from './pages/Authentication/AuthenticationCallBack';
import Overview from './pages/Overview/Overview';
import Information from './pages/Information/Information';
import Organization from './pages/Organization/Organization';
import ExpressPortal from './pages/ExpressPortal/ExpressPortal';
import { RouteConstants } from './core/constants/routes.constants';
import CenterLayout from './components/CenterLayout/CenterLayout';
import { Picture as InformationPicture } from './pages/Information/Picture/Picture';
import { Signature as InformationSignature } from './pages/Information/Signature/Signature';
import CreateAccount from './pages/CreateAccount/CreateAccount';
import { ContainerLayout } from './components/ContainerLayout/ContainerLayout';
import { CommonGlobal } from './CommonGlobal';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import CreateAccountOnixExpress from './pages/CreateAccountOnixExpress/CreateAccountOnixExpress';
import ResetPasswordOnixExpress from './pages/ResetPasswordOnixExpress/ResetPasswordOnixExpress';

function App() {
  return (
    <BrowserRouter>
      <CommonGlobal></CommonGlobal>
      <Routes>
        <Route path='/' element={<LoginLayout />}>
          <Route path='' element={<Navigate to={RouteConstants.Login} replace />}></Route>
          <Route path={RouteConstants.Login} element={<Login />}></Route>
          <Route path={RouteConstants.AuthCallBack} element={<AuthenticationCallBack />}></Route>
          <Route path={RouteConstants.Logout} element={<Logout />} />
          <Route path={RouteConstants.CreateAccount} element={<CreateAccount />} />
          <Route path={RouteConstants.CreateAccountOnixExpress} element={<CreateAccountOnixExpress />} />
          <Route path={RouteConstants.ResetPassword} element={<ResetPassword />} />
          <Route path={RouteConstants.ResetPasswordOnixExpress} element={<ResetPasswordOnixExpress />} />
        </Route>

        <Route path='' element={<Authentication />}>
          <Route path='' element={<HomeLayout />}>

            <Route path='' element={<CenterLayout />}>
              <Route index element={<Overview />}></Route>
              <Route path={RouteConstants.Overview} element={<Overview />}></Route>

              <Route path={RouteConstants.Information.Root}>
                <Route index element={<Information />}></Route>
                <Route path={RouteConstants.Information.Picture} element={<InformationPicture />}></Route>
                <Route path={RouteConstants.Information.Signature} element={<InformationSignature />}></Route>
              </Route>
            </Route>

            <Route path='' element={<ContainerLayout />}>
              <Route path={RouteConstants.Organization} element={<Organization />}></Route>
              <Route path={RouteConstants.ExpressPortal} element={<ExpressPortal />}></Route>
            </Route>

          </Route>
        </Route>

        <Route path={RouteConstants.Others} element={<Navigate to={RouteConstants.Overview} />}></Route>
      </Routes>

    </BrowserRouter>
  )
}

export default App;