import "./SingleSelectionPanel.scss";
import { ActionButton, Dropdown, IconButton, IDropdownOption, List, Panel, SearchBox, Separator } from "@fluentui/react";
import { ReactElement, useCallback, useEffect, useState } from "react";

type Props = {
    headerText?: string | ReactElement;
    options: IDropdownOption[];
    selectedKey?: any;
    onChange?: (options: IDropdownOption) => void;
    placeholder?: string;
}

export default function SingleSelectionDropdown(props: Props) {
    const {
        options: optionProps, placeholder, headerText,
        selectedKey,
        onChange
    } = props;

    const [isOpenPanel, setOpenPanel] = useState(false);
    const [searchText, setSearchText] = useState<string>('');
    const [options, setOptions] = useState<IDropdownOption[]>([]);

    const onRenderBody = () => {
        return (
            <List className="body-dropdown" items={options} onRenderCell={onRenderCell} />
        );
    }

    const onRenderCell = (option?: IDropdownOption) => {
        if (option) {
            return <>
                <ActionButton
                    styles={
                        {
                            textContainer: { display: "flex", justifyContent: "flex-start", width: "100%" }
                        }
                    }
                    onClick={() => onRenderCellClick(option)}>
                    {option?.text}
                </ActionButton>
            </>;
        }
        return null;
    }

    const onRenderCellClick = (option: IDropdownOption) => {
        if (onChange && option) {
            onChange(option);
        }
        closePanel();
    }

    const onRenderHeader = () => {
        return (
            <div className="header-dropdown">
                <span>{headerText}</span>
                <SearchBox placeholder={placeholder} onChange={(e, newValue) => onChangeText(newValue)} />
                <IconButton onClick={closePanel} className="close-button" iconProps={{ iconName: "Cancel" }} />
                <Separator />
            </div>
        )
    }

    const closePanel = () => {
        setSearchText('');
        setOpenPanel(false);
    }

    const onRenderFooter = () => {
        return <></>;
    }

    const onDropdownRenderTitle = useCallback(() => {
        if (optionProps && optionProps?.length > 0 && selectedKey !== undefined) {
            const option = optionProps.find(m => m.key === selectedKey);
            if (option) {
                return <>{option.text}</>
            }
            return <></>;
        }
        return <></>;
    }, [optionProps, selectedKey])

    useEffect(() => {
        changeOptions(searchText);
    }, [optionProps, searchText])

    const onChangeText = (newValue?: string) => {
        let value = newValue || '';
        setSearchText(value);
    }

    const changeOptions = (value: string) => {
        if (value === '') {
            setOptions(optionProps);
            return;
        }
        const lowerCaseValue = value.toLowerCase();
        const newOptions = optionProps.filter(option => option.text.toLowerCase().indexOf(lowerCaseValue) > -1);
        setOptions(newOptions);
    }

    return (
        <div className="single-selection-panel">
            <Dropdown
                options={optionProps}
                placeholder={placeholder}
                onClick={(event: any) => {
                    if (event?.preventDefault) {
                        event.preventDefault();
                    }
                    setOpenPanel(true);
                }}
                onRenderTitle={onDropdownRenderTitle}
                multiSelect={false}
                selectedKey={selectedKey} />
            <Panel
                isOpen={isOpenPanel}
                onRenderBody={onRenderBody}
                onRenderFooter={onRenderFooter}
                onRenderHeader={onRenderHeader}
                hasCloseButton={false}
                styles={
                    {
                        scrollableContent: {
                            position: "relative"
                        }
                    }
                }
                onDismiss={closePanel}
            >
            </Panel>
        </div>
    )
}