import './OtherOrganization.scss';
import { Card } from '@onix/common';
import { Section } from '../../../components/Section/Section';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Organization } from '../../../models/organization.model';
import { getMemberHomeOrganization, getMemberOrganization, leaveOrganization } from '../../../services/organization.service';
import { ActionButton, Link, MessageBarType, SearchBox } from '@fluentui/react';
import { Controller, useForm } from 'react-hook-form';
import { IColumn, SelectionMode } from '@fluentui/react/lib/DetailsList';
import { showConfirmDialog, showErrorDialogWithResponse } from '../../../services/dialog.service';
import { Table } from '../../../components/Table/Table';
import { useEnterPress } from '../../../hooks/useEnterPress';
import { getMemberCompanyInvitations, updateMainCompany } from '../../../services/account.service';
import {
  OrganizationActions,
  otherControlOrganizationSelector,
  otherOrganizationSelector,
} from '../../../states/organization/organizationSlice';
import { appDispatch } from '../../../states/store';
import { useSelector } from 'react-redux';
import { showHomeLayoutMessageBar } from '../../../services/message-bar.service';
import { refreshToken } from '../../../services/authentication.service';

const defaultFormValue = {
  textSearch: '',
  sortColumn: 'name',
  isDescending: false,
  pageIndex: 1,
};

export function OtherOrganization() {
  const dispatch = appDispatch;
  const organizations = useSelector(otherOrganizationSelector);
  const otherControls = useSelector(otherControlOrganizationSelector);

  const textSearchRef = useRef(null);

  const [translate] = useTranslation();
  const { setValue, getValues, control } = useForm({ defaultValues: defaultFormValue });

  const [organizationSelected, setOrganizationSelected] = useState<Organization | null>(null);
  const defaultColumns = [
    {
      key: 'CompanyName',
      name: 'CaptionResource.Name',
      fieldName: 'companyName',
      minWidth: 70,
      maxWidth: 700,
      isResizable: true,
      isPadded: true,
      isSorted: true,
      isSortedDescending: defaultFormValue.isDescending,
      onRenderHeader: ({ column }: any) => {
        return <>{translate(column.name)}</>;
      },
      onRender: (item?: any, index?: number, column?: IColumn) => {
        return <b>{item[column?.fieldName ?? 'companyName']}</b>;
      },
    },
    {
      key: 'Role',
      name: 'CaptionResource.YourRole',
      fieldName: 'role',
      minWidth: 70,
      isResizable: true,
      isPadded: true,
      onRenderHeader: ({ column }: any) => {
        return <>{translate(column.name)}</>;
      },
      onRender: (item?: any, index?: number, column?: IColumn) => {
        if (!item?.role || item?.role === '') {
          return <span>{translate('CaptionResource.None')}</span>;
        }
        if (item?.role === 'Administrator') {
          return <span>{translate('Settings.Administrator')}</span>;
        }
        return item[column?.fieldName ?? 'role'];
      },
    },
  ];
  const [columns, setColumns] = useState<IColumn[]>(defaultColumns);

  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    setColumns(defaultColumns);
  }, [translate]);

  useEffect(() => {
    setValue('textSearch', otherControls.text);
  }, [otherControls]);

  const search = async () => {
    const request = getValues();
    try {
      const res = await getMemberOrganization(request);
      dispatch(OrganizationActions.updateOtherOrg(res.items));
    } catch (err) {
      showErrorDialogWithResponse(err);
    }
  };

  useEnterPress(textSearchRef, () => {
    onSelectionChange([]);
    search();
  });

  const onClearTextSearch = async () => {
    setValue('textSearch', '');
    onSelectionChange([]);
    await search();
  };

  const setHomeOrganization = useCallback(async () => {
    if (!organizationSelected) {
      return;
    }
    try {
      await updateMainCompany(organizationSelected.contactId);
      getMemberHomeOrganization();
      setValue('textSearch', '');
      search();
      showHomeLayoutMessageBar(<>{translate('CaptionResource.SetAsHomeOrgMessage', { 0: organizationSelected.companyName })}</>, {
        messageBarType: MessageBarType.success,
        dismissButtonAriaLabel: 'Close',
      });
      await refreshToken();
    } catch (err) {
      showErrorDialogWithResponse(err);
    }
  }, [organizationSelected]);

  const leave = useCallback(async () => {
    if (!organizationSelected) {
      return;
    }
    try {
      await leaveOrganization(organizationSelected.contactId, organizationSelected.companyId);
      getMemberHomeOrganization();
      showHomeLayoutMessageBar(<>{translate('CaptionResource.LeftOrganizationMessage', { 0: organizationSelected.companyName })}</>, {
        messageBarType: MessageBarType.success,
        dismissButtonAriaLabel: 'Close',
      });
      search();
      setOrganizationSelected(null);
      getMemberCompanyInvitations({});
    } catch (err) {
      showErrorDialogWithResponse(err);
    }
  }, [organizationSelected]);

  const openConfirmLeaveDialog = () => {
    showConfirmDialog({
      dialogContentProps: {
        title: <>{translate('CaptionResource.LeaveOrganization')}</>,
        showCloseButton: true,
        subText: translate('CaptionResource.ConfirmationLeaveOrganization', { 0: organizationSelected?.companyName ?? '' }),
      },
      onAccept: leave,
    });
  };

  const openSetAsHomeDialog = () => {
    showConfirmDialog({
      dialogContentProps: {
        title: <>{translate('CaptionResource.SetAsHomeOrg')}</>,
        showCloseButton: true,
        subText: translate('CaptionResource.ConfirmationSetAsHomeOrg', { 0: organizationSelected?.companyName ?? '' }),
      },
      onAccept: setHomeOrganization,
    });
  };

  const onSelectionChange = (items: Organization[]) => {
    let organizationSelected = null;
    if (items.length > 0) {
      organizationSelected = items[0];
    }
    setOrganizationSelected(organizationSelected);
  };

  const onSortOptionChange = (sortColumn: IColumn, newColumns: IColumn[]) => {
    setValue('isDescending', sortColumn?.isSortedDescending ?? false);
    setValue('sortColumn', sortColumn?.key ?? '');

    setColumns(newColumns);
    search();
  };

  return (
    <Section
      className="home-organization-section"
      title={translate('CaptionResource.OtherOrganization')}
      description={
        <>
          <span>{translate('CaptionResource.OtherOrganizationDescription') + ' '}</span>
          <Link target="_blank" href={translate('CaptionResource.OtherOrgLearnMore')} underline>
            {translate('CommonResource.LearnMore')}
          </Link>
        </>
      }
    >
      <Card>
        <div className="other-organization-table">
          <div className="controls">
            <div className="left">
              <ActionButton iconProps={{ iconName: 'Home' }} onClick={openSetAsHomeDialog} disabled={organizationSelected === null}>
                {translate('CaptionResource.SetAsHomeOrganization')}
              </ActionButton>
              <ActionButton iconProps={{ iconName: 'Leave' }} onClick={openConfirmLeaveDialog} disabled={organizationSelected === null}>
                {translate('CaptionResource.Leave')}
              </ActionButton>
            </div>
            <div className="right">
              <Controller
                name="textSearch"
                control={control}
                render={({ field }) => (
                  <SearchBox
                    {...field as any}
                    ref={textSearchRef}
                    placeholder={translate('CaptionResource.OtherOrgSearchPlaceholder')}
                    onClear={onClearTextSearch}
                  ></SearchBox>
                )}
              />
            </div>
          </div>
          <div className="table">
            {organizations !== null && organizations.length > 0 && (
              <Table
                items={organizations}
                columns={columns}
                selectionMode={SelectionMode.single}
                onSortOptionChange={onSortOptionChange}
                onSelectionChange={onSelectionChange}
              />
            )}
            {organizations?.length === 0 && (
              <div className="empty-list">
                <span>{translate('CommonResource.EmptyMessageHits')}</span>
              </div>
            )}
          </div>
        </div>
      </Card>
    </Section>
  );
}
