import { IStyle } from "@fluentui/react";
import { IModalProps } from "@fluentui/react/lib/components/Modal";
import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReactElement } from "react";
import { RootState } from "../store";

export type ModalState = {
    isOpen: boolean;
    component: string | ReactElement;
    title: string | ReactElement;
    description: string | ReactElement;
    confirmEvent: any;
    cancelEvent?: any;
    style?: IStyle;
    props: IModalProps;
}

const initialState: ModalState = {
    isOpen: false,
    component: '',
    title: '',
    description: '',
    cancelEvent: () => {},
    confirmEvent: () => {},
    style: {},
    props: {
        isBlocking: false
    }
};

export type ShowModalPayload = {
    component: string| ReactElement;
    confirmEvent: any;
    cancelEvent?: any;
    title?: string| ReactElement;
    description?: string| ReactElement;
    props?: IModalProps;
    style?: IStyle;
}

const ModalSlice = createSlice({
    name: 'modal',
    initialState: initialState,
    reducers: {
        showModal: (state, action: PayloadAction<ShowModalPayload>) => {
            const payload = action.payload;
            state.isOpen = true;
            state.component = payload.component;
            state.title = payload.title ?? '';
            state.description = payload.description ?? '';
            state.confirmEvent = payload.confirmEvent;
            state.cancelEvent = payload?.cancelEvent ? payload.cancelEvent : () => {};
            state.props = { isBlocking: false };
            state.style = payload.style ?? { height: 'auto', width: 'auto' };
            if (payload?.props) {
                state.props.isBlocking = payload.props.isBlocking;
            }
        },
        hiddenModal: (state) => {
            state.isOpen = false;
        }
    }
});

export const ModalActions = ModalSlice.actions;

export const ModalReducer = ModalSlice.reducer;


// SELECTOR 
const selectSelf = (state: RootState) => state.modal;
export const modalSelector = createSelector(selectSelf, state => state);