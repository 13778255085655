import { Stack, Text } from '@fluentui/react';
import { UserPrivileges } from '@onix/common';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OnixInspectLogo from '../../../assets/images/Onix_Inspect.svg';
import OnixMakerLogo from '../../../assets/images/Onix_Maker.svg';
import OnixTagLogo from '../../../assets/images/Onix_Tag.svg';
import OnixWorkLogo from '../../../assets/images/Onix_Work.svg';
import OnixWorkerLogo from '../../../assets/images/Onix_Worker.svg';
import { hasPrivilege } from '../../../services/authentication.service';
import { isAndroid, isiOS } from '../../../services/common.service';
import { AppIcon } from './AppIcon/AppIcon';
interface IAppList {
  companyName?: string;
}

export default function AppList(props: IAppList) {
  const { companyName } = props;
  const [translate] = useTranslation();
  const [hasOnixWorkPrivilege, setHasOnixWorkPrivilege] = useState<boolean>(false);
  const [hasOnixTagPrivilege, setHasOnixTagPrivilege] = useState<boolean>(false);
  const [hasOnixWorkerPrivilege, setHasOnixWorkerPrivilege] = useState<boolean>(false);
  const [hasOnixInspectPrivilege, setHasOnixInspectPrivilege] = useState<boolean>(false);
  const [hasOnixMakerPrivilege, setHasOnixMakerPrivilege] = useState<boolean>(false);
  const [isNotAccessAnyApp, setIsNotAccessAnyApp] = useState<boolean>(false);

  useEffect(() => {
    setHasOnixWorkPrivilege(hasPrivilege(UserPrivileges.OnixWork));
    setHasOnixTagPrivilege(hasPrivilege(UserPrivileges.OnixTag));
    setHasOnixWorkerPrivilege(hasPrivilege(UserPrivileges.OnixWorker));
    setHasOnixInspectPrivilege(hasPrivilege(UserPrivileges.OnixInspect));
    setHasOnixMakerPrivilege(hasPrivilege(UserPrivileges.OnixMaker));
  }, []);

  useEffect(() => {
    if (!hasOnixWorkPrivilege && !hasOnixTagPrivilege && !hasOnixWorkerPrivilege && !hasOnixInspectPrivilege && !hasOnixMakerPrivilege) {
      setIsNotAccessAnyApp(true);
    } else {
      setIsNotAccessAnyApp(false);
    }
  }, [hasOnixInspectPrivilege, hasOnixTagPrivilege, hasOnixWorkPrivilege, hasOnixWorkerPrivilege, hasOnixMakerPrivilege]);

  const onOnixWorkClick = () => {
    window.open('https://prod.onix.com');
  };
  const onOnixTagClick = () => {
    window.open('https://pickandpack.onix.com');
  };
  const onOnixWorkerClick = () => {
    let url = 'https://www.onix.com/onix-worker/';
    if (isAndroid()) {
      url = `https://worker-api.onix.com/redirect/android-app`;
    } else if (isiOS()) {
      url = `https://worker-api.onix.com/redirect/ios-app`;
    }
    window.open(url);
  };

  const onOnixInspectClick = () => {
    let url = 'https://www.onix.com/onix-inspect/';
    if (isAndroid()) {
      url = `https://play.google.com/store/apps/details?id=com.onix.onixinspector.android`;
    } else if (isiOS()) {
      url = `https://apps.apple.com/us/app/onix-inspect/id1574138549`;
    }
    window.open(url);
  };

  const onOnixMakerClick = useCallback(() => {
    window.open('https://maker.onix.com');
  }, []);

  return (
    <>
      <Text variant="xLarge" className="action-title">
        {translate('Home.AppsThanYouCanAccess')} {companyName}
      </Text>
      {isNotAccessAnyApp ? (
        <Text>{translate('Home.NotHaveAccessToAnyApp')}</Text>
      ) : (
        <Stack className="apps" horizontal disableShrink wrap horizontalAlign="center">
          {hasOnixWorkPrivilege && <AppIcon src={OnixWorkLogo} onClick={onOnixWorkClick} label="Onix Work"></AppIcon>}
          {hasOnixWorkerPrivilege && <AppIcon src={OnixWorkerLogo} onClick={onOnixWorkerClick} label="Onix Worker"></AppIcon>}
          {hasOnixInspectPrivilege && <AppIcon src={OnixInspectLogo} onClick={onOnixInspectClick} label="Onix Inspect"></AppIcon>}
          {hasOnixTagPrivilege && <AppIcon src={OnixTagLogo} onClick={onOnixTagClick} label="Onix Pick and Pack"></AppIcon>}
          {hasOnixMakerPrivilege && <AppIcon src={OnixMakerLogo} onClick={onOnixMakerClick} label="Onix Maker"></AppIcon>}
        </Stack>
      )}
    </>
  );
}
