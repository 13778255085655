import './Modal.scss';
import { IModalProps, IStyle, Modal as ModalFluent, IconButton } from '@fluentui/react';
import { useId } from '@fluentui/react-hooks';
import { ReactElement, useCallback, useEffect, useState } from 'react';

type Props = {
  title?: string | ReactElement;
  description?: string | ReactElement;
  children?: string | ReactElement;

  props?: IModalProps;

  style?: IStyle;

  isModalOpen: boolean;

  closeIcon?: boolean;

  // Event output
  onDismiss?: () => void;
};

export default function Modal(props: Props) {
  const { title: propTitle, description: propDescription, children, props: modalProps, style, isModalOpen, closeIcon } = props;

  const titleId = useId('title');

  const [title, setTitle] = useState(propTitle ?? '');
  const [description, setDescription] = useState(propDescription ?? '');
  useEffect(() => {
    setTitle(propTitle ?? '');
  }, [propTitle]);

  useEffect(() => {
    setDescription(propDescription ?? '');
  }, [propDescription]);

  const onDismiss = useCallback(() => {
    if (props.onDismiss) {
      props.onDismiss();
    }
  }, [props.onDismiss]);

  return (
    <>
      <ModalFluent
        {...modalProps}
        titleAriaId={titleId}
        isOpen={isModalOpen}
        onDismiss={onDismiss}
        containerClassName="modal-container"
        styles={{
          main: style != null ? style : { width: 'auto', height: 'auto' },
        }}
      >
        <div className="modal-wrapper">
          <div className="modal-title">
            <span>
              <div className="text">{title}</div>
              <div className="icon">
                {closeIcon && <IconButton className="btn-clear-text" iconProps={{ iconName: 'Cancel' }} onClick={onDismiss} />}
              </div>
            </span>
            <span>{description}</span>
          </div>
          {children && <div className="modal-body">{children}</div>}
        </div>
      </ModalFluent>
    </>
  );
}
