import { ApiConstants } from "../core/constants/api.constants";
import { Organization } from "../models/organization.model";
import { ExpressActions } from "../states/express/expressSlice";
import { OrganizationActions } from "../states/organization/organizationSlice";
import { appDispatch } from "../states/store";
import { getAsync, putAsync} from "./base.service";

const dispatch = appDispatch;

export const getMemberHomeOrganization = async (): Promise<Organization> => {
  const url = ApiConstants.MyAccount.Organization.GetMemberHome();
  const homeOrganizations = await getAsync(url);
  let homeOrganization = homeOrganizations.length > 0 ? homeOrganizations[0] : null;

  dispatch(OrganizationActions.updateHomeOrg(homeOrganization));
  return homeOrganization;
};

export const getMemberOrganization = (request: any): Promise<{items: Organization[], pageIndex: number, totalItems: number}> => {
  const url = ApiConstants.MyAccount.Organization.GetMember();
  return getAsync(url, request);
}

export const getExpressOrganization = async () => {
  const url = ApiConstants.MyAccount.Organization.GetExpress();
  const yourAcceses = await getAsync(url);
  dispatch(ExpressActions.updateYourAccesses(yourAcceses));
  return yourAcceses;
}

export const leaveOrganization = (contactId: number, companyId: number) => {
  const url = ApiConstants.MyAccount.Organization.Leave(contactId,companyId);
  return putAsync(url);
}