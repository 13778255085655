import { IDialogContentProps, IModalProps, IMessageBarProps } from '@fluentui/react';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ReactElement } from 'react';
import { DialogTypeEnum } from '../../core/constants/enums/dialog-type.enum';
import { RootState } from '../store';
export interface AppState {
  isLoading: boolean;
  totalQueueLoading: number;
  errorDialog: ErrorDialogState;
  confirmDialog: ConfirmDialogState;
  homeLayoutMessageBars: HomeLayoutMessageBarState[];
}

const initialState: AppState = {
  isLoading: false,
  totalQueueLoading: 0,
  errorDialog: {
    isHidden: true,
    type: DialogTypeEnum.Error,
    message: '',
    messageCaption: '',
    title: '',
  },
  confirmDialog: {
    isHidden: true,
    dialogContentProps: {},
    content: '',
    onAccept: () => {},
    onCancel: () => {},
  },
  homeLayoutMessageBars: [],
};
export interface ErrorDialogState {
  isHidden: boolean;
  type: DialogTypeEnum;
  message: string;
  messageCaption: string;
  title: string;
}

export interface ConfirmDialogState {
  isHidden: boolean;
  content: string | ReactElement;

  dialogContentProps?: IDialogContentProps;
  modalProps?: IModalProps;

  footerContent?: ReactElement;
  onAccept?: Function;
  onCancel?: Function;
}

export interface HomeLayoutMessageBarState {
  id: string;
  props: IMessageBarProps;
  content: string | ReactElement;
}

const AppSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    showLoading: (state) => {
      state.totalQueueLoading++;
      state.isLoading = true;
    },
    hiddenLoading: (state) => {
      state.totalQueueLoading--;
      state.isLoading = state.totalQueueLoading > 0;
    },
    showErrorDialog: (state, action: PayloadAction<{ type: DialogTypeEnum; message: string; messageCaption: string; title: string }>) => {
      state.errorDialog.isHidden = false;
      state.errorDialog.type = action.payload.type;
      state.errorDialog.message = action.payload.message;
      state.errorDialog.messageCaption = action.payload.messageCaption;
      state.errorDialog.title = action.payload.title;
    },
    hiddenErrorDialog: (state) => {
      state.errorDialog.isHidden = true;
    },
    showConfirmDialog: (state, action: PayloadAction<ConfirmDialogState>) => {
      state.confirmDialog = {
        ...(action.payload as any),
        isHidden: false,
      };
    },
    hiddenConfirmDialog: (state) => {
      state.confirmDialog.isHidden = true;
    },
    showMessageBar: (state, action: PayloadAction<HomeLayoutMessageBarState>) => {
      const newHomeLayoutMessageBars = [...state.homeLayoutMessageBars, action.payload];
      state.homeLayoutMessageBars = Object.assign([], newHomeLayoutMessageBars);
    },
    hiddenMessageBar: (state, action: PayloadAction<string>) => {
      const newHomeLayoutMessageBars = state.homeLayoutMessageBars.filter((m) => m.id !== action.payload);
      state.homeLayoutMessageBars = Object.assign([], newHomeLayoutMessageBars);
    },
  },
});

export const AppActions = AppSlice.actions;

export const AppReducer = AppSlice.reducer;

// SELECTOR

const selectSelf = (state: RootState) => state.app;
export const appSelector = createSelector(selectSelf, (state) => state);
export const appIsLoadingSelector = createSelector(selectSelf, (state) => state.isLoading);
export const errorSelector = createSelector(selectSelf, (state) => state.errorDialog);
export const confirmDialogSelector = createSelector(selectSelf, (state) => state.confirmDialog);
export const homeLayoutMessageBarSelector = createSelector(selectSelf, (state) => state.homeLayoutMessageBars);
