import './ImageEditor.scss';
import { useState, useCallback, useEffect, useRef } from 'react';
import Cropper from 'react-easy-crop';
import { Slider } from '@fluentui/react';

type Props = {
  imageUrl: string;
  minZoom?: number;
  maxZoom?: number;
  step?: number;
  aspect?: number;

  onChange?: any;
};

export default function ImageEditor(props: Props) {
  const cropperRef = useRef<Cropper>(null);

  const [aspect, setAspect] = useState(props?.aspect ? props.aspect : 1);
  const [imageDataUrl, setImageDataUrl] = useState(props.imageUrl);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);

  useEffect(() => {
    setImageDataUrl(props.imageUrl);
  }, [props?.imageUrl]);

  useEffect(() => {
    setAspect(props?.aspect ? props.aspect : 1);
  }, [props?.aspect]);

  const onCropComplete = useCallback(
    async (_: any, croppedAreaPixels: any) => {
      if (props?.imageUrl && props?.onChange && typeof props.onChange === 'function') {
        props.onChange(croppedAreaPixels);
      }
    },
    [props.imageUrl, props?.onChange]
  );

  const onMediaLoaded = async () => {
    // ON IOS DEVICES: NOT TRIGGER onCropComplete in the first time
    setTimeout(() => {
      const cropData = cropperRef.current?.getCropData();
      if (cropData) {
        onCropComplete({}, cropData.croppedAreaPixels);
      }
    }, 50);
  };

  return (
    <div className="image-editor-container">
      <div className="crop-container">
        <Cropper
          ref={cropperRef}
          image={imageDataUrl}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onMediaLoaded={onMediaLoaded}
          onZoomChange={setZoom}
        />
      </div>
      <div className="image-editor-controls">
        <Slider
          className="zoom-slider"
          value={zoom}
          min={props?.minZoom ? props.minZoom : 1}
          max={props?.maxZoom ? props.maxZoom : 3}
          step={props?.step ? props.step : 0.1}
          onChange={setZoom}
          valueFormat={(value) => value.toFixed(1)}
        ></Slider>
      </div>
    </div>
  );
}
