import "./EnvironmentBanner.scss";
import ApplicationSetting from "../../../core/constants/application.constants";
import { useTranslation } from 'react-i18next';
export default function EnvironmentBanner() {
    const [translate] = useTranslation();
    if(ApplicationSetting.enableBanner){
        return (
            <div className="environment-banner">
                <span className="environment-name"> 
                    {ApplicationSetting.name}
                </span>
                &nbsp;
                <span>
                {translate('CaptionResource.Environment')}
                </span>
            </div>
        )
    }
    return (<div></div>)
}