export enum UserPrivileges {
  Access = 1,
  OnixWork = 2,
  OnixWorker = 3,
  OnixInspect = 4,
  OnixTag = 5,
  OnixTransfer = 6,
  OnixSource = 7,
  OnixToolStore = 8,
  OnixMaker = 9,
  Equipment = 10,
  Job = 13,
  FilingJob = 14,
  FilingOtherJob = 15,
  BeResPerManInsJob = 16, // Be responsible person on mandatory inspection job,
  BeResPerCertJob = 17, // Be responsible person on certificate job,
  BeVerPerManInsJob = 18, // Be verifier person on mandatory inspection job,
  Issue = 19,
  Document = 20,
  Order = 21,
  Rental = 24,
  Product = 25,
  Dashboard = 26,
  Companies = 27,
  ViewAndChart = 28,
  ShareView = 29,
  ManageAllView = 30,
  ShareChart = 31,
  ManageAllChart = 32,
  MapAndPosition = 33,
  Administration = 35,
  EquipmentReceivalConversion = 36,
  ManageUsersAndRoles = 37,
  ManageLocations = 38,
  ManageAreas = 39,
  ManageDepartments = 40,
  ManageInstruction = 41,
  ManageProjects = 42,
  ManageConnectedCompanies = 43,
  ManageCtrlCategories = 44,
  ManageChecklist = 45,
  ManageForms = 46,
  ManageCounters = 47,
  ManageBaseDocuments = 48,
  ManageSystemSetup = 49,
  ManageWorkflow = 50,
  ManageCompanyProfile = 51,
  UseCustomReport = 52,
  Integration = 53,
  ExternalDataSource = 54,
  ExternalLinking = 55,
  License = 56,
  AvailableJobClass = 58,
  DefaultJobClass = 59,
  EditActiveStatus = 60,
  EditLocation = 61,
  EditArea = 62,
  EditPlacement = 63,
  EditContacts = 64,
  //EditNotes = 65,
  EditOnixId = 66,
  EditAllRemainingInfo = 67,
  CloseIssue = 68,
}
