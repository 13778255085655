import './PhoneNumber.scss';
import { useCallback, useEffect, useState } from 'react';
import { IDropdownOption, TextField } from '@fluentui/react';
import { getCurrentLanguage } from '../../services/common.service';
import { useTranslation } from 'react-i18next';
import { SingleSelectionPanel } from '@onix/common';

type Props = {
  phoneNumber?: string;
  onChange?: (
    {country, nationalPhone, phoneNumber, isValidPhone}:
    {country?: string, nationalPhone?: string, phoneNumber?: string, isValidPhone?: boolean}
    ) => void;
};

export function PhoneNumber(props: Props) {
  const phoneUtil = require("google-libphonenumber").PhoneNumberUtil.getInstance();
  const [translate] = useTranslation();
  const [options, setOptions] = useState([] as IDropdownOption[]);
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState<string>('');

  const { phoneNumber, onChange } = props;

  useEffect(() => {
    const currentLanguage = getCurrentLanguage();
    const intlDisplayNames = new Intl.DisplayNames(currentLanguage, { type: 'region' });

    const supportedCallingCodes = phoneUtil.getSupportedCallingCodes() as number[];
    const countryOptions = supportedCallingCodes.map(callingCode => {
      const countryCode = phoneUtil.getRegionCodeForCountryCode(callingCode);
      const countryName = intlDisplayNames.of(countryCode);
        return {
          key: countryCode,
          text: `${countryName} (+${callingCode})`,
          title: `${countryName} (+${callingCode})`,
          data: {
            countryCode,
            countryName,
            callingCode,
          },
        } as IDropdownOption;
    })
    .filter(m => m.data.countryName !== "001")
    .sort((prevItem, nextItem) => prevItem.text.localeCompare(nextItem.text));

    const options = [
      { key: '', text: translate('CaptionResource.None'), title: translate('CaptionResource.None') },
      ...countryOptions
    ] as IDropdownOption[];

    setOptions(options);
  }, [translate]);

  useEffect(() => {
    setTypingPhoneNumber(phoneNumber);
  }, [phoneNumber]);

  const onChangeEmit = useCallback(
    (country: string, nationalPhone: string) => {
      if (onChange) {
        let isValidPhone;
        let phoneNumber = '';
        try {
          const rawPhoneValue = phoneUtil.parseAndKeepRawInput(nationalPhone, country);
          isValidPhone = phoneUtil.isValidNumber(rawPhoneValue);
          phoneNumber = `+${rawPhoneValue.getCountryCodeOrDefault()}${nationalPhone}`;
        }
        catch {
          isValidPhone = false;
        }
        onChange({ country, nationalPhone, phoneNumber, isValidPhone });
      }
    },
    [onChange]
  );

  const countryOnChange = useCallback(
    (option?: IDropdownOption) => {
      let temporaryCountry = (option?.key as string) ?? '';
      setCountry(temporaryCountry);
      onChangeEmit(temporaryCountry, phone);
    },
    [phone, onChangeEmit]
  );

  const setTypingPhoneNumber = useCallback(
    (value?: string, country?: string) => {
      let temporaryPhone = value ?? '';
      let temporaryCountry = country ?? '';
      if (temporaryPhone) {
        let rawPhoneValue;
        try {
          rawPhoneValue = phoneUtil.parseAndKeepRawInput(temporaryPhone, temporaryCountry);
        }
        catch(err) {
          rawPhoneValue = null;
        }
        if (rawPhoneValue) {
          temporaryCountry = phoneUtil.getRegionCodeForNumber(rawPhoneValue);
          temporaryPhone = `${rawPhoneValue.getNationalNumber()}`;
        }
      }

      setPhone(temporaryPhone);
      setCountry(temporaryCountry);
      onChangeEmit(temporaryCountry, temporaryPhone);
    },
    [onChangeEmit]
  );

  return (
    <div className="phone-number-container">
      <SingleSelectionPanel 
        options={options} selectedKey={country}
        headerText={translate("CaptionResource.CountryCode")}
        onChange={countryOnChange}/>

      <TextField onChange={(e, newValue) => setTypingPhoneNumber(newValue, country)} value={phone}></TextField>
    </div>
  );
}
