import "./ExpressPortal.scss";
import { useTranslation } from "react-i18next";
import { OpenInvitations } from "./OpenInvitations/OpenInvitations";
import { YourAccess } from "./YourAccess/YourAccess";

export default function ExpressPortal() {
    const [translate] = useTranslation();

    return (
        <>
            <div className="express-container">
                <span className="title-page">
                    {translate('CaptionResource.ExpressPortal')}
                </span>
                <div className="sections">
                    <YourAccess />
                    <OpenInvitations />
                </div>
            </div>
        </>

    )
}