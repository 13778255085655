import { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { completeAuthentication, hasToken } from '../../services/authentication.service';

export default function AuthenticationCallBack() {
  const [isLogged, setIsLogged] = useState(false);

  useEffect(() => {
    async function completeAuth() {
      const hasTokenCurrently = hasToken();
      setIsLogged(hasTokenCurrently);

      if (!isLogged) {
        await completeAuthentication();
        setIsLogged(true);
      }
    }
    completeAuth();
  }, []);

  return <div>{isLogged ? <Navigate to={'/home'}></Navigate> : <div></div>}</div>;
}
