import imageCompression from 'browser-image-compression';

export const compressFileToBase64 = (file: string): Promise<string> => {
  const result = new Promise<string>(async (resolve) => {
    compressFile(await imageCompression.getFilefromDataUrl(file, '', undefined)).then((compressedFile) => {
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        if (!fileReader.result || fileReader.result.toString().length === 0) {
          throw new Error('Cannot compress image');
        }
        resolve('' + fileReader?.result);
      };
      fileReader.readAsDataURL(compressedFile);
    });
  });
  return result;
};

export const getDataUrlFromFile = (file: File): Promise<string> => {
  return imageCompression.getDataUrlFromFile(file);
};

export const compressFile = (file: File): Promise<File> => {
  const options = {
    maxSizeMB: 1,
    maxIteration: 30,
    //maxWidthOrHeight: 1920,
    //initialQuality: 0.7,
  };
  return imageCompression(file, options);
};

export function isImageFile(fileName: string) {
  var idxDot = fileName.lastIndexOf('.') + 1;
  var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
  const isImageFile = extFile == 'jpg' || extFile == 'jpeg' || extFile == 'png';
  return isImageFile;
}

export const createImage = (url: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', (error) => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
    resolve(image);
  });
};

export function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * Returns the new bounding area of a rotated rectangle.
 */
export function rotateSize(width: number, height: number, rotation: number) {
  const rotRad = getRadianAngle(rotation);

  return {
    width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  };
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 */
export default async function getDataUrlImageCropped(
  imageSrc: string,
  pixelCrop: any,
  rotation = 0,
  flip = { horizontal: false, vertical: false }
): Promise<any> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return null;
  }

  const rotRad = getRadianAngle(rotation);

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(image.width, image.height, rotation);

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth;
  canvas.height = bBoxHeight;

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
  ctx.rotate(rotRad);
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
  ctx.translate(-image.width / 2, -image.height / 2);

  // draw rotated image
  ctx.drawImage(image, 0, 0);

  // croppedAreaPixels values are bounding box relative
  // extract the cropped image using these values
  const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image at the top left corner
  ctx.putImageData(data, 0, 0);

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    try {
      resolve(canvas.toDataURL());
    } catch (err) {
      reject(err);
    }
  });
}

export function getBase64(file: any): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
}
