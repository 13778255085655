import { ActionButton, Icon, Link, MessageBarType } from '@fluentui/react';
import { Card } from '@onix/common';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Section } from '../../../components/Section/Section';
import { DialogTypeEnum } from '../../../core/constants/enums/dialog-type.enum';
import { ContactUserFilter } from '../../../models/contact-user-filter.model';
import { createMemberUserByInvitation, getMemberCompanyInvitations } from '../../../services/account.service';
import { refreshToken } from '../../../services/authentication.service';
import { showErrorDialogWithResponse } from '../../../services/dialog.service';
import { showHomeLayoutMessageBar } from '../../../services/message-bar.service';
import { getMemberHomeOrganization, getMemberOrganization } from '../../../services/organization.service';
import { homeOrganizationSelector, invitationSelector, OrganizationActions } from '../../../states/organization/organizationSlice';
import { appDispatch } from '../../../states/store';
import { domainEmailSelector } from '../../../states/user/userSlice';

export function OpenInvitations() {
  const dispatch = appDispatch;
  const [translate] = useTranslation();
  const invitations = useSelector(invitationSelector);
  const domainEmail = useSelector(domainEmailSelector);
  const homeOrganization = useSelector(homeOrganizationSelector);

  useEffect(() => {
    async function getData() {
      try {
        await getMemberCompanyInvitations({});
      } catch (err) {
        showErrorDialogWithResponse(err);
      }
    }
    getData();
  }, []);

  const createUser = async (invitation: ContactUserFilter) => {
    try {
      await createMemberUserByInvitation(invitation.contactUserFilterId);
      showHomeLayoutMessageBar(
        <>{translate('CaptionResource.CreateMemberUserByInvitationSuccess', { 0: invitation.companyDescription })}</>,
        { messageBarType: MessageBarType.success, dismissButtonAriaLabel: 'Close' }
      );
      await getMemberCompanyInvitations({});
      await getMemberHomeOrganization();
      const res = await getMemberOrganization({});
      dispatch(OrganizationActions.updateOtherOrg(res.items));
      dispatch(OrganizationActions.updateOtherControls({ text: '' }));
      if (homeOrganization == null) {
        await refreshToken();
      }
    } catch (err) {
      showErrorDialogWithResponse(err);
    }
  };

  return (
    <Section
      className="organization-invitation"
      title={translate('CaptionResource.OpenInvitations')}
      description={
        <>
          <span dangerouslySetInnerHTML={{ __html: translate('CaptionResource.OpenInvitationDescription', { 0: domainEmail }) }} />{' '}
          <Link target="_blank" href={translate('CaptionResource.OpenInvitationLearnMore')} underline>
            {translate('CommonResource.LearnMore')}
          </Link>
        </>
      }
    >
      <>
        {invitations.length > 0 &&
          invitations.map((invitation) => {
            return (
              <Card key={invitation.contactUserFilterId}>
                <div className="card-content" key={invitation.contactUserFilterId}>
                  <Icon iconName="CompanyDirectory" styles={{ root: { fontSize: '24px' } }} />
                  <div className="details">
                    <span className="name">{invitation.companyDescription}</span>
                  </div>
                  <div className="right-controls">
                    <ActionButton onClick={() => createUser(invitation)}>{translate('CaptionResource.CreateUser')}</ActionButton>
                  </div>
                </div>
              </Card>
            );
          })}

        {invitations.length === 0 && (
          <Card>
            <div className="card-content">
              <Icon iconName="CompanyDirectory" styles={{ root: { fontSize: '24px' } }} />
              <div className="details">
                <span className="name text-grey-130">{translate('CaptionResource.OpenInvitationEmpty')}</span>
              </div>
            </div>
          </Card>
        )}
      </>
    </Section>
  );
}
