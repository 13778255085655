import { WebStorageStateStore } from 'oidc-client';
import { ApplicationSettingInterface } from '../models/application-setting.model';

export const environment: ApplicationSettingInterface = {
  name: 'Prod',
  clientApplicationId: 17,
  apiUrl: 'https://onix-my-account-api.azurewebsites.net',
  identityApiUrl: 'https://login.onix.com',
  enableBanner: false,
  oidcSetting: {
    authority: 'https://login.onix.com',
    client_id: '11212512535239de7cae2d85a30b9999',
    redirect_uri: 'https://myaccount.onix.com/auth-callback',
    post_logout_redirect_uri: 'https://myaccount.onix.com/login',
    response_type: 'code',
    scope: 'openid email onixmyaccount offline_access',
    filterProtocolClaims: false,
    loadUserInfo: false,
    automaticSilentRenew: false,
    //checkSessionInterval: 2000,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
    monitorSession: true,
    includeIdTokenInSilentRenew: false,
    revokeAccessTokenOnSignout: true,
  },
};
