import { ActionButton, DefaultButton, PrimaryButton } from '@fluentui/react';
import { ImageService } from '@onix/common';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { Area } from 'react-easy-crop/types';
import { useTranslation } from 'react-i18next';
import { AppActions } from '../../states/app/appSlice';
import { appDispatch } from '../../states/store';
import ImageEditor from '../ImageEditor/ImageEditor';
import './UpdateImageContainer.scss';

type Props = {
  title?: string;
  description?: string | ReactElement;
  imageUrl?: string;

  aspect?: number;
  acceptFiles?: string;
  breadcrumbChildren?: any;
  children?: any;
  nonePicture?: string | ReactElement;

  addPhotoText?: string;
  removePhotoText?: string;

  cancelOnChange?: any;
  saveOnChange?: any;
  errorOnChange?: any;
};

export function UpdateImageContainer(props: Props) {
  const fileInputRef = useRef(null);

  const [translate] = useTranslation();

  const [aspect, setAspect] = useState(props?.aspect ? props.aspect : 1);

  const [currentImage, setCurrentImage] = useState('');
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);

  const { saveOnChange, cancelOnChange, errorOnChange } = props;

  useEffect(() => {
    setAspect(props?.aspect ? props.aspect : 1);
  }, [props?.aspect]);

  useEffect(() => {
    if (props?.imageUrl) {
      setCurrentImage(props.imageUrl);
    }
  }, [props?.imageUrl]);

  const saveClick = useCallback(async () => {
    if (saveOnChange) {
      let dataToSave: string | null = null;
      if (croppedAreaPixels != null) {
        appDispatch(AppActions.showLoading());
        const croppedImageData = await ImageService.getDataUrlImageCropped(currentImage, croppedAreaPixels);
        dataToSave = await ImageService.compressFileToBase64(croppedImageData);
        appDispatch(AppActions.hiddenLoading());
      }
      saveOnChange(dataToSave);
    }
  }, [croppedAreaPixels, saveOnChange]);

  const cancelClick = useCallback(() => {
    if (cancelOnChange) {
      cancelOnChange();
    }
  }, [cancelOnChange]);

  const addPhotoClick = useCallback(() => {
    if (fileInputRef) {
      if ((fileInputRef.current as any)?.click) {
        (fileInputRef.current as any).click();
      }
    }
  }, []);

  const removePhotoClick = useCallback(() => {
    setCurrentImage('');
    setCroppedAreaPixels(null);
    const currentElement = fileInputRef?.current as any;

    if (currentElement.files && currentElement.files.length > 0) {
      currentElement.value = null;
    }
  }, []);

  const fileOnChange = useCallback(
    async (data: any) => {
      try {
        const currentTarget = data.currentTarget;
        if (currentTarget?.files && currentTarget.files[0]) {
          let message = 'CommonResource.UnableUploadImageOver4MB';
          if (ImageService.isImageFile(currentTarget.files[0].name)) {
            const imageMbSize = currentTarget.files[0].size / (1024 * 1024);
            if (imageMbSize > 4) {
              errorOnChange(message);
              return;
            }
            setCurrentImage(await ImageService.getDataUrlFromFile(currentTarget.files[0]));
            return;
          }
          message = 'CommonResource.onlyImage';
          currentTarget.value = null;
          if (errorOnChange) {
            errorOnChange(message);
            return;
          }
          throw new Error(message);
        }
      } catch (err) {
        throw err;
      }
    },
    [errorOnChange]
  );

  return (
    <>
      <div className="update-image-container">
        <span className="breadcrumb">{props?.breadcrumbChildren}</span>
        <span className="title-page">{props?.title}</span>
        <span>{props?.description}</span>
        <div className="image-editor">
          {currentImage && <ImageEditor imageUrl={currentImage} onChange={setCroppedAreaPixels} aspect={aspect}></ImageEditor>}
          {!currentImage && (
            <div className="none-wrapper" onClick={addPhotoClick}>
              {props?.nonePicture}
            </div>
          )}
        </div>
        <div className="update-image-controls">
          <ActionButton iconProps={{ iconName: 'Add' }} onClick={addPhotoClick}>
            {props?.addPhotoText}
          </ActionButton>
          {currentImage && (
            <ActionButton iconProps={{ iconName: 'Delete' }} onClick={removePhotoClick}>
              {props?.removePhotoText}
            </ActionButton>
          )}
        </div>
        <div className="update-image-footer">
          <PrimaryButton onClick={saveClick}>{translate('CommonResource.Save')}</PrimaryButton>
          <DefaultButton onClick={cancelClick}>{translate('CommonResource.Cancel')}</DefaultButton>
        </div>

        <input hidden ref={fileInputRef} type="file" accept={props?.acceptFiles} onChange={fileOnChange}></input>
      </div>
    </>
  );
}
