import { PrimaryButton, Text, TextField } from '@fluentui/react';
import { LanguageHelper } from '@onix/common';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OnixWorkLogo from '../../assets/images/Onix Account logo.svg';
import { RouteConstants } from '../../core/constants/routes.constants';
import { UserAccount } from '../../models/user-account.model';
import { getInformationToResetPassword, resetPassword } from '../../services/account.service';
import { showErrorDialogWithResponse } from '../../services/dialog.service';
import './ResetPassword.scss';

const defaultFormValue = {
  password: '',
  confirmPassword: '',
  passwordHint: '',
};

export default function ResetPassword() {
  const [translate, { isInitialized, changeLanguage }] = useTranslation();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, touchedFields },
    trigger,
  } = useForm({ defaultValues: defaultFormValue, mode: 'onTouched' });

  const [searchParams] = useSearchParams();

  const [userAccount, setUserAccount] = useState<UserAccount | null>(null);
  const [key, setKey] = useState<string | null>(null);
  const [isSuccess, setSuccess] = useState(false);
  const [returnUrl, setReturnUrl] = useState<string>('');

  useEffect(() => {
    async function getSuggestionData() {
      try {
        const request = { param: searchParams.get('param') };
        const userAccount = await getInformationToResetPassword(request);

        setUserAccount(userAccount);
        updateLanguage(userAccount);
        setKey(userAccount?.key);
        setReturnUrl(userAccount.loginUrl);
      } catch {
        backToLoginPage();
      }
    }
    getSuggestionData();
  }, []);

  useEffect(() => {
    updateLanguage();
  }, [isInitialized]);

  const updateLanguage = (userAccountData = userAccount) => {
    if (userAccountData) {
      const language = LanguageHelper.getLanguageCode(userAccountData.languageId);
      changeLanguage(language);
    }
  };

  const submitForm = async () => {
    if (!key) {
      return;
    }
    try {
      const request = {
        key,
        email: userAccount?.email,
        ...getValues(),
      };
      await resetPassword(request);
      setSuccess(true);
    } catch (err) {
      showErrorDialogWithResponse(err);
    }
  };

  const backToLoginPage = () => {
    if (userAccount?.loginUrl) {
      window.location.replace(userAccount.loginUrl);
      return;
    }
    navigate(`/${RouteConstants.Login}`);
  };

  return (
    <div className="primary-box reset-password-container">
      <img className="onix-logo" src={OnixWorkLogo} alt="logo"></img>
      <Text variant="xxLarge" className="title">
        {translate('Login.cmdResetPassword')}
      </Text>
      <Text variant="medium" className="email">
        {translate('CommonResource.Email')}: {userAccount?.email}
      </Text>
      {isSuccess ? (
        <>
          <span>{translate('CaptionResource.ResetPasswordSuccessfully')}</span>
          <PrimaryButton onClick={backToLoginPage}>{translate('Login.cmdReturnLogin')}</PrimaryButton>
        </>
      ) : (
        <>
          {returnUrl && <Text variant="medium">{translate('Login.ResetPasswordText')}</Text>}
          <form className="form-default" onSubmit={handleSubmit(submitForm)}>
            <div className="row">
              <div className="controls">
                <Controller
                  name="password"
                  control={control}
                  rules={{
                    required: translate('CaptionResource.Required'),
                    pattern: {
                      value: RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/),
                      message: translate('Login.Message.Error_PasswordStrength'),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      label={translate('Login.NewPassword')}
                      {...field}
                      autoComplete="off"
                      canRevealPassword
                      revealPasswordAriaLabel="Show password"
                      type="password"
                      onChange={(e: any) => {
                        field.onChange(e);
                        if (touchedFields?.confirmPassword) {
                          trigger('confirmPassword');
                        }
                        if (touchedFields?.passwordHint) {
                          trigger('passwordHint');
                        }
                      }}
                    />
                  )}
                />
              </div>
              <div className="errors">
                {errors?.password && <span dangerouslySetInnerHTML={{ __html: errors?.password?.message ?? '' }}></span>}
              </div>
            </div>
            <div className="row">
              <div className="controls">
                <Controller
                  name="confirmPassword"
                  control={control}
                  rules={{ validate: (value) => value === getValues('password') }}
                  render={({ field }) => (
                    <TextField
                      label={translate('Login.PasswordRepeat')}
                      {...(field as any)}
                      autoComplete="off"
                      canRevealPassword
                      revealPasswordAriaLabel="Show password"
                      type="password"
                    />
                  )}
                />
              </div>
              <div className="errors">{errors?.confirmPassword && <span>{translate('Login.MsgNotSamePass')}</span>}</div>
            </div>

            <div className="row">
              <div className="controls">
                <Controller
                  name="passwordHint"
                  control={control}
                  rules={{ validate: (value) => value !== getValues('password') || value === '', deps: 'password' }}
                  render={({ field }) => <TextField {...field} label={translate('Login.PasswordHint')} />}
                />
              </div>
              <div className="errors">{errors?.passwordHint && <span>{translate('Login.Message.Error_PasswordHint')}</span>}</div>
            </div>

            <div className="row">
              <PrimaryButton type="submit" style={{ width: '100%', marginTop: '1rem' }} text={translate('Login.cmdContinue')} />
            </div>
          </form>
        </>
      )}
    </div>
  );
}
