import { DefaultButton, Icon, MessageBarType, PersonaSize, PrimaryButton } from '@fluentui/react';
import { Card } from '@onix/common';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';
import { DialogTypeEnum } from '../../core/constants/enums/dialog-type.enum';
import { RouteConstants } from '../../core/constants/routes.constants';
import { sendResetPasswordEmail } from '../../services/account.service';
import { showErrorDialog } from '../../services/dialog.service';
import { showHomeLayoutMessageBar } from '../../services/message-bar.service';
import { signaturePictureSelector, userImageUrlSelector, userSelector } from '../../states/user/userSlice';
import './Information.scss';
import { ProfileSection } from './ProfileSection/ProfileSection';

export default function Information() {
  const navigate = useNavigate();
  const [translate] = useTranslation();
  const userAvatar = useSelector(userImageUrlSelector);
  const signaturePicture = useSelector(signaturePictureSelector);
  const userAccount = useSelector(userSelector);

  const navigateToChangePicturePage = useCallback(() => {
    navigate(RouteConstants.Information.Picture);
  }, [navigate]);

  const navigateToChangeSignaturePage = useCallback(() => {
    navigate(RouteConstants.Information.Signature);
  }, [navigate]);

  const changePasswordClick = async () => {
    if (userAccount.ssoEnforcement) {
      showErrorDialog(
        '',
        translate('Home.CannotChangeManagedAccountPassword', { 0: userAccount.companyName }),
        DialogTypeEnum.CustomTitle,
        translate('Home.CannotChangePassword')
      );
      return;
    }
    const result = await sendResetPasswordEmail();
    if (result?.success) {
      showHomeLayoutMessageBar(translate('CaptionResource.ResetPasswordSentMessage'), {
        messageBarType: MessageBarType.success,
        dismissButtonAriaLabel: 'Close',
      });
    }
  };

  return (
    <div className="your-info-container">
      <div className="top-bar">
        <span className="title-page">{translate('CaptionResource.YourInfo')}</span>

        <div className="change-password-btn">
          <PrimaryButton onClick={changePasswordClick}>
            <div className="content-button">
              <Icon iconName="PasswordField"></Icon>
              {translate('Login.cmdChangePassword')}
            </div>
          </PrimaryButton>
        </div>
      </div>
      <div className="first-section">
        <Card>
          {userAvatar && <Avatar props={{ imageUrl: userAvatar, size: PersonaSize.size120 }}></Avatar>}

          <span>{translate('CaptionResource.YourInfoPictureCaption')}</span>
          <div>
            <DefaultButton text={translate('CaptionResource.ChangePicture')} onClick={navigateToChangePicturePage} />
          </div>
        </Card>
        <Card>
          {signaturePicture && (
            <div className="signature-picture">
              <img src={signaturePicture} alt=""></img>
            </div>
          )}
          <span>{translate('CaptionResource.YourInfoSignatureCaption')}</span>
          <div>
            <DefaultButton text={translate('CaptionResource.ChangeSignature')} onClick={navigateToChangeSignaturePage} />
          </div>
        </Card>
      </div>
      <ProfileSection></ProfileSection>
    </div>
  );
}
