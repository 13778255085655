import { Image, Label } from '@fluentui/react';
import { MouseEventHandler } from 'react';
import './index.scss';

type AppIconProps = {
  src?: string;
  onClick?: MouseEventHandler<HTMLImageElement>;
  label?: string;
};

export function AppIcon(props: AppIconProps) {
  return (
    <div className="app-item-button" onClick={props.onClick}>
      <Image src={props.src}></Image>
      <Label>{props.label}</Label>
    </div>
  );
}
