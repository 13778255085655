import { IMessageBarProps } from "@fluentui/react";
import { ReactElement } from "react";
import { AppActions } from "../states/app/appSlice";
import { appDispatch } from "../states/store";
import { v4 as uuid } from 'uuid';

const dispatch = appDispatch;

export const showHomeLayoutMessageBar = (content: string | ReactElement,
    props?: IMessageBarProps, isAutoHide = true, ms = 5000) => {
    const uniqueId = uuid();

    const onDismissFunction = props?.onDismiss;

    const payload = {
        id: uniqueId,
        props: props ? {
            ...props,
            onDismiss: onDismissFunction ? 
            () => {
                dispatch(AppActions.hiddenMessageBar(uniqueId));
                onDismissFunction();
            } : 
            () => {
                dispatch(AppActions.hiddenMessageBar(uniqueId));
            }
        } : {},
        content
    };

    dispatch(AppActions.showMessageBar(payload));
    
    if (isAutoHide) {
        setTimeout(() => {
            dispatch(AppActions.hiddenMessageBar(uniqueId))
        }, ms);
    }
}

export const hiddenHomeLayoutMessageBar = (id: string) => {
    dispatch(AppActions.hiddenMessageBar(id));
}