import { Dropdown, IDropdownOption, Label, PartialTheme, PrimaryButton, Stack, Text, TextField, ThemeProvider } from '@fluentui/react';
import { LanguageEnum, LanguageHelper } from '@onix/common';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { PhoneNumber } from '../../components/PhoneNumber/PhoneNumber';
import { RouteConstants } from '../../core/constants/routes.constants';
import { createAccountOnixExpress, getAccountInfoSuggestionOnixExpress } from '../../services/account.service';
import { getSupportedLanguages } from '../../services/common.service';
import { showErrorDialog } from '../../services/dialog.service';
import './CreateAccountOnixExpress.scss';

const defaultFormValue = {
  email: '',
  fullName: '',
  userFirstName: '',
  userLastName: '',
  userCellPhone: '',
  password: '',
  passwordRepeat: '',
  passwordHint: '',
  languageId: LanguageEnum.English,
  key: '',
  loginUrl: '',
  companyExpressId: 0,
  isHandRequestUser: '',
};

export default function CreateAccountOnixExpress() {
  const navigate = useNavigate();
  const [translate, i18n] = useTranslation();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<any>();
  const [customTheme, setcCstomTheme] = useState<PartialTheme>();
  const [languageOptions, setLanguageOptions] = useState([] as IDropdownOption[]);
  const [isCreated, setIsCreated] = useState(false);
  const [hasRequestUser, setHasRequestUser] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues: defaultFormValue, mode: 'onTouched' });

  const changeLanguage = useCallback(
    async (languageId: any) => {
      var language = LanguageHelper.getLanguageCode(languageId);
      i18n.changeLanguage(language);
    },
    [i18n]
  );

  useEffect(() => {
    const currentLanguages = getSupportedLanguages();
    const mappingLanguages = currentLanguages.map((language) => {
      return {
        key: language.id,
        text: language.name,
        title: language.name,
      } as IDropdownOption;
    });
    setLanguageOptions(mappingLanguages);
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {
        const request = { param: searchParams.get('param') };
        const res = await getAccountInfoSuggestionOnixExpress(request);

        if (res.success) {
          if (!res.userAccount.companyExpressSetupInfo) {
            window.location.href = res.userAccount.loginUrl;
          }
          setData(res.userAccount);
          const customTheme: PartialTheme = {
            palette: {
              themePrimary: '#8D3A8E',
              themeDarkAlt: '#8D3A8E',
            },
          };
          setcCstomTheme(customTheme);
          changeLanguage(res.userAccount.languageId);
        } else {
          navigate(`/${RouteConstants.Login}`);
        }
      } catch {
        navigate(`/${RouteConstants.Login}`);
      }
    }

    fetchData();
  }, [changeLanguage, navigate, searchParams]);

  useEffect(() => {
    if (data) {
      const keys = Object.keys(defaultFormValue);
      keys.forEach((key) => {
        setValue(key as any, (data as any)[key]);
      });
    }
  }, [data, setValue]);

  const phoneNumberChange = (data: any) => {
    if (data.nationalPhone === '' || data.country === '') {
      setValue('userCellPhone', '');
      clearErrors('userCellPhone');
      if (data.nationalPhone === '') {
        return;
      }
    }
    const isValidPhone = data?.isValidPhone ?? false;
    if (!isValidPhone) {
      setError('userCellPhone', { type: 'invalidPhoneNumber' });
      setValue('userCellPhone', '');
      return;
    }
    clearErrors('userCellPhone');
    const userCellPhone = data.phoneNumber;
    setValue('userCellPhone', userCellPhone);
  };

  const submit = async () => {
    handleSubmit(async (request) => {
      request.companyExpressId = data.companyExpressSetupInfo.companyId;
      request.isHandRequestUser = data.isHandRequestUser;
      const response = await createAccountOnixExpress(request);
      if (response.success) {
        setIsCreated(true);
        setHasRequestUser(response.hasRequestUser);
      } else {
        showErrorDialog(response.messageCaption, response.message);
      }
    })();
  };

  const backtoLogin = () => {
    window.location.href = data.loginUrl;
    //navigate(data.loginUrl);
  };

  return (
    data &&
    data.companyExpressSetupInfo && (
      <div className="create-account-onix-express  primary-box">
        <ThemeProvider theme={customTheme}>
          {data && (
            <Stack className="create-account-container">
              <div
                style={{
                  color: data.companyExpressSetupInfo.primaryTextColor,
                  backgroundColor: data?.companyExpressSetupInfo.primaryColor,
                }}
                className="create-account-header"
              >
                <img
                  className="onix-logo"
                  src={`data:image/jpeg;base64,${data.companyExpressSetupInfo.companyLogoImageBase64}`}
                  alt="logo"
                ></img>
                <Text style={{ color: data.companyExpressSetupInfo.primaryTextColor }} variant="xxLarge">
                  {translate('Login.CreateAnAccount')}
                </Text>
                <Text style={{ color: data.companyExpressSetupInfo.primaryTextColor }} variant="medium" className="email">
                  {translate('CommonResource.Email')}: {data.email}
                </Text>
              </div>
              <div className="create-account-body">
                {!isCreated ? (
                  <Stack>
                    <form className="form-default">
                      <div className="row">
                        <Controller
                          name="languageId"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              label={translate('CaptionResource.PreferredLanguage')}
                              {...field}
                              onChange={(e, option) => {
                                if (option) {
                                  field.onChange(option.key as any);
                                  changeLanguage(option?.key);
                                }
                              }}
                              selectedKey={field.value}
                              options={languageOptions}
                            />
                          )}
                        />
                      </div>
                      <div className="row">
                        <Controller
                          name="userFirstName"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              label={translate('CommonResource.FirstName')}
                              {...(field as any)}
                              onBlur={(e: any) => setValue('userFirstName', e.target.value.trim())}
                            />
                          )}
                          rules={{
                            validate: (value) => {
                              if (!value.trim()) return translate('CaptionResource.Required');
                            },
                            maxLength: { value: 50, message: translate('CaptionResource.OverMaxLength') },
                          }}
                        />
                        <div className="errors">{errors?.userFirstName && <span>{errors?.userFirstName.message}</span>}</div>
                      </div>
                      <div className="row">
                        <Controller
                          name="userLastName"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              label={translate('CommonResource.LastName')}
                              {...(field as any)}
                              onBlur={(e: any) => setValue('userLastName', e.target.value.trim())}
                            />
                          )}
                          rules={{
                            validate: (value) => {
                              if (!value.trim()) return translate('CaptionResource.Required');
                            },
                            maxLength: { value: 50, message: translate('CaptionResource.OverMaxLength') },
                          }}
                        />
                        <div className="errors">{errors?.userLastName && <span>{errors?.userLastName.message}</span>}</div>
                      </div>
                      <div className="row">
                        <div className="controls phone-number">
                          <Label>{translate('Settings.MobilePhone')}:</Label>
                          <PhoneNumber phoneNumber={data?.userCellPhone ?? ''} onChange={phoneNumberChange}></PhoneNumber>
                        </div>
                        <div className="errors">
                          {errors?.userCellPhone && <span>{translate('CaptionResource.InvalidPhoneNumberMA')}</span>}
                        </div>
                      </div>
                      <div className="row">
                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              type="password"
                              label={translate('Login.Password')}
                              autoComplete="off"
                              canRevealPassword
                              revealPasswordAriaLabel="Show password"
                              {...(field as any)}
                            />
                          )}
                          rules={{
                            required: translate('CaptionResource.Required'),
                            pattern: {
                              value: RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/),
                              message: translate('Login.Message.Error_PasswordStrength'),
                            },
                          }}
                        />
                        <div className="errors">
                          {errors?.password && <span dangerouslySetInnerHTML={{ __html: errors?.password.message ?? '' }}></span>}
                        </div>
                      </div>
                      <div className="row">
                        <Controller
                          name="passwordRepeat"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              type="password"
                              label={translate('Login.PasswordRepeat')}
                              autoComplete="off"
                              canRevealPassword
                              revealPasswordAriaLabel="Show password"
                              {...(field as any)}
                            />
                          )}
                          rules={{
                            validate: (val: string) => {
                              if (watch('password') !== val) {
                                return translate('Login.MsgNotSamePass');
                              }
                            },
                          }}
                        />
                        <div className="errors">{errors?.passwordRepeat && <span>{errors?.passwordRepeat.message}</span>}</div>
                      </div>
                      <div className="row">
                        <Controller
                          name="passwordHint"
                          control={control}
                          render={({ field }) => <TextField label={translate('Login.PasswordHint')} {...(field as any)} />}
                          rules={{
                            maxLength: { value: 4000, message: translate('CaptionResource.OverMaxLength') },
                          }}
                        />
                        <div className="errors">{errors?.passwordHint && <span>{errors?.passwordHint.message}</span>}</div>
                      </div>
                      <PrimaryButton
                        style={{
                          boxShadow: '0 0 15px 0 rgba(0,0,0,.18)',
                          border: '0px',
                          color: data.companyExpressSetupInfo.primaryTextColor,
                          backgroundColor: data?.companyExpressSetupInfo.primaryColor,
                        }}
                        className="btn"
                        text={translate('Login.cmdContinue')}
                        onClick={submit}
                      />
                    </form>
                  </Stack>
                ) : (
                  <Stack>
                    {hasRequestUser ? (
                      <>
                        <Text variant="medium">
                          {translate('CommonResource.CreateSuccessAccountRequestExpressLine1').replace(
                            '{0}',
                            data.companyExpressSetupInfo.companyName
                          )}
                        </Text>
                        <Text variant="medium">{translate('CommonResource.CreateSuccessAccountRequestExpressLine2')}</Text>
                      </>
                    ) : (
                      <>
                        <Text variant="medium">{translate('Login.AccountCreated')}</Text>
                        <PrimaryButton
                          style={{
                            boxShadow: '0 0 15px 0 rgba(0,0,0,.18)',
                            border: '0px',
                            color: data.companyExpressSetupInfo.primaryTextColor,
                            backgroundColor: data?.companyExpressSetupInfo.primaryColor,
                          }}
                          className="btn"
                          text={translate('Login.cmdReturnLogin')}
                          onClick={backtoLogin}
                        />
                      </>
                    )}
                  </Stack>
                )}
                <div className="copyright">
                  <span>{translate('CommonResource.PoweredBy')} </span>
                  <a target="_blank" href="https://www.onix.com/" rel="noreferrer">
                    Onix Work
                  </a>
                </div>
              </div>
            </Stack>
          )}
        </ThemeProvider>
      </div>
    )
  );
}
