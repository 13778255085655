import ApplicationSetting from './application.constants';

const myAccountApiUrl = ApplicationSetting.apiUrl;
const identityApiUrl = ApplicationSetting.identityApiUrl;

export const ApiConstants = {
  MyAccount: {
    Setting: {
      GetLanguages: `${myAccountApiUrl}/settings/languages`,
    },
    Account: {
      GetSuggestion: () => myAccountApiUrl + `/accounts/get-suggestion`,
      GetSuggestionOnixExpress: () => myAccountApiUrl + `/accounts/get-suggestion-onix-express`,
      CreateAccount: () => myAccountApiUrl + `/accounts/create`,
      CreateAccountOnixExpress: () => myAccountApiUrl + `/accounts/create-onix-express`,
      GetInfoToResetPassword: () => myAccountApiUrl + `/accounts/reset-passwords`,
      GetInfoToResetPasswordOnixExpress: () => myAccountApiUrl + `/accounts/reset-passwords-onix-express`,
      ResetPassword: () => myAccountApiUrl + `/accounts/reset-passwords`,
      ResetPasswordOnixExpress: () => myAccountApiUrl + `/accounts/reset-passwords-onix-express`,
      GetInformation: () => myAccountApiUrl + `/accounts`,
      UpdateAvatarPicture: () => myAccountApiUrl + `/accounts/pictures`,
      UpdateSignature: () => myAccountApiUrl + `/accounts/signatures`,
      UpdateInformation: () => myAccountApiUrl + `/accounts`,
      UpdatePreferredLanguage: () => myAccountApiUrl + `/accounts/preferred-languages`,
      UpdateMainCompany: (contactId: number) => myAccountApiUrl + `/accounts/main-companies?contactId=${contactId}`,
      GetMemberCompanyInvitation: () => myAccountApiUrl + `/accounts/member-invitations`,
      GetExpressCompanyInvitation: () => myAccountApiUrl + `/accounts/express-invitations`,
      CreateMemberUserByInvitation: (invitationId: number) => myAccountApiUrl + `/accounts/member-invitations/${invitationId}/users`,
      CreateExpressUserByInvitation: (invitationId: number) => myAccountApiUrl + `/accounts/express-invitations/${invitationId}/users`,
    },
    Organization: {
      GetMember: () => myAccountApiUrl + `/organizations/members`,
      GetMemberHome: () => myAccountApiUrl + `/organizations/members/homes`,
      GetExpress: () => myAccountApiUrl + `/organizations/expresses`,
      Leave: (contactId: number, companyId: number) =>
        myAccountApiUrl + `/organizations/leaves?contactId=${contactId}&companyId=${companyId}`,
    },
  },
  Identity: {
    Account: {
      // GetContact: () => identityApiUrl + `/accounts/contact`
      SendResetPasswordEmail: () => identityApiUrl + `/accounts/send-reset-password-email`,
    },
  },
};
