import { ReactElement } from "react";
import { Outlet } from "react-router-dom";
import "./CenterLayout.scss";

type Props = {
    children?: string | ReactElement;
}

export default function CenterLayout(props: Props) {

    return (
        <div className="center-layout">
            <div className="center-layout-content">
                { props?.children || <Outlet/> }
            </div>
        </div>
    )
}