import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactUserFilter } from "../../models/contact-user-filter.model";
import { Organization } from "../../models/organization.model";
import { RootState } from "../store";

type OrganizationState = {
    home: Organization | null;
    others: Organization[] | [];
    otherControls: {
        text: string
    };
    invitations: ContactUserFilter[] | [];
}

const initialState: OrganizationState = {
    home: null,
    others: [],
    otherControls: {
        text: ''
    },
    invitations: []
};

const OrganizationSlice = createSlice({
    name: 'organization',
    initialState: initialState,
    reducers: {
        updateHomeOrg: (state, action: PayloadAction<Organization | null>) => {
            state.home = action.payload;
        },
        updateOtherOrg: (state, action: PayloadAction<Organization[]>) => {
            let otherOrg: Organization[] | [] = [];
            if (action.payload) {
                otherOrg = action.payload;
            }
            state.others = otherOrg;
        },
        updateOtherControls: (state, action: PayloadAction<{text: string}>) => {
            state.otherControls = action.payload;
        },
        updateInvitations: (state, action: PayloadAction<ContactUserFilter[]>) => {
            state.invitations = action.payload;
        }
    }
});

export const OrganizationActions = OrganizationSlice.actions;

export const OrganizationReducer = OrganizationSlice.reducer;


// SELECTOR 
const selectSelf = (state: RootState) => state.organization;
export const organizationSelector = createSelector(selectSelf, state => state);
export const homeOrganizationSelector = createSelector(selectSelf, state => state.home);
export const otherOrganizationSelector = createSelector(selectSelf, state => state.others);
export const otherControlOrganizationSelector = createSelector(selectSelf, state => state.otherControls);
export const invitationSelector = createSelector(selectSelf, state => state.invitations);