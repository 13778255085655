import { Dropdown, IDropdownOption, Label, PrimaryButton, Stack, Text, TextField } from '@fluentui/react';
import { LanguageEnum, LanguageHelper } from '@onix/common';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OnixWorkLogo from '../../assets/images/Onix Work - Text Color.svg';
import { PhoneNumber } from '../../components/PhoneNumber/PhoneNumber';
import { RouteConstants } from '../../core/constants/routes.constants';
import { createAccount, getAccountInfoSuggestion } from '../../services/account.service';
import { getSupportedLanguages } from '../../services/common.service';
import { showErrorDialog } from '../../services/dialog.service';
import './CreateAccount.scss';

const defaultFormValue = {
  email: '',
  fullName: '',
  userFirstName: '',
  userLastName: '',
  userCellPhone: '',
  password: '',
  passwordRepeat: '',
  passwordHint: '',
  languageId: LanguageEnum.English,
  key: '',
};

export default function CreateAccount() {
  const navigate = useNavigate();
  const [translate, i18n] = useTranslation();
  const [searchParams] = useSearchParams();
  const [data, setData] = useState(defaultFormValue);
  const [languageOptions, setLanguageOptions] = useState([] as IDropdownOption[]);
  const [isCreated, setIsCreated] = useState(false);

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({ defaultValues: defaultFormValue, mode: 'onTouched' });

  const changeLanguage = useCallback(
    async (languageId: any) => {
      var language = LanguageHelper.getLanguageCode(languageId);
      i18n.changeLanguage(language);
    },
    [i18n]
  );

  useEffect(() => {
    const currentLanguages = getSupportedLanguages();
    const mappingLanguages = currentLanguages.map((language) => {
      return {
        key: language.id,
        text: language.name,
        title: language.name,
      } as IDropdownOption;
    });
    setLanguageOptions(mappingLanguages);
  }, []);

  useEffect(() => {
    async function fetchData() {
      const request = { param: searchParams.get('param') };
      const res = await getAccountInfoSuggestion(request);
      if (res.success) {
        setData(res.userAccount);
        changeLanguage(res.userAccount.languageId);
      } else {
        navigate(`/${RouteConstants.Login}`);
      }
    }

    fetchData();
  }, [changeLanguage, navigate, searchParams]);

  useEffect(() => {
    const keys = Object.keys(defaultFormValue);
    keys.forEach((key) => {
      setValue(key as any, (data as any)[key]);
    });
  }, [data, setValue]);

  const phoneNumberChange = (data: any) => {
    if (data.nationalPhone === '' || data.country === '') {
      setValue('userCellPhone', '');
      clearErrors('userCellPhone');
      if (data.nationalPhone === '') {
        return;
      }
    }
    const isValidPhone = data?.isValidPhone ?? false;
    if (!isValidPhone) {
      setError('userCellPhone', { type: 'invalidPhoneNumber' });
      setValue('userCellPhone', '');
      return;
    }
    clearErrors('userCellPhone');
    const userCellPhone = data.phoneNumber;
    setValue('userCellPhone', userCellPhone);
  };

  const submit = async () => {
    handleSubmit(async (request) => {
      const response = await createAccount(request);
      if (response.success) {
        setIsCreated(true);
      } else {
        showErrorDialog(response.messageCaption, response.message);
      }
    })();
  };

  const backtoLogin = () => {
    navigate(`/${RouteConstants.Login}`);
  };

  return (
    <>
      {data && (
        <Stack className="create-account-container primary-box">
          <img className="onix-logo" src={OnixWorkLogo} alt="logo"></img>
          <Text variant="xxLarge" className="title">
            {translate('Login.CreateAnAccount')}
          </Text>
          <Text variant="medium" className="email">
            {translate('CommonResource.Email')}: {data.email}
          </Text>
          {!isCreated ? (
            <Stack>
              <form className="form-default">
                <div className="row">
                  <Controller
                    name="languageId"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        label={translate('CaptionResource.PreferredLanguage')}
                        {...field}
                        onChange={(e, option) => {
                          if (option) {
                            field.onChange(option.key as any);
                            changeLanguage(option?.key);
                          }
                        }}
                        selectedKey={field.value}
                        options={languageOptions}
                      />
                    )}
                  />
                </div>
                <div className="row">
                  <Controller
                    name="userFirstName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={translate('CommonResource.FirstName')}
                        {...(field as any)}
                        onBlur={(e: any) => setValue('userFirstName', e.target.value.trim())}
                      />
                    )}
                    rules={{
                      validate: (value) => {
                        if (!value.trim()) return translate('CaptionResource.Required');
                      },
                      maxLength: { value: 50, message: translate('CaptionResource.OverMaxLength') },
                    }}
                  />
                  <div className="errors">{errors?.userFirstName && <span>{errors?.userFirstName.message}</span>}</div>
                </div>
                <div className="row">
                  <Controller
                    name="userLastName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        label={translate('CommonResource.LastName')}
                        {...(field as any)}
                        onBlur={(e: any) => setValue('userLastName', e.target.value.trim())}
                      />
                    )}
                    rules={{
                      validate: (value) => {
                        if (!value.trim()) return translate('CaptionResource.Required');
                      },
                      maxLength: { value: 50, message: translate('CaptionResource.OverMaxLength') },
                    }}
                  />
                  <div className="errors">{errors?.userLastName && <span>{errors?.userLastName.message}</span>}</div>
                </div>
                <div className="row">
                  <div className="controls phone-number">
                    <Label>{translate('Settings.MobilePhone')}:</Label>
                    <PhoneNumber phoneNumber={data?.userCellPhone ?? ''} onChange={phoneNumberChange}></PhoneNumber>
                  </div>
                  <div className="errors">{errors?.userCellPhone && <span>{translate('CaptionResource.InvalidPhoneNumberMA')}</span>}</div>
                </div>
                <div className="row">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="password"
                        label={translate('Login.Password')}
                        autoComplete="off"
                        canRevealPassword
                        revealPasswordAriaLabel="Show password"
                        {...(field as any)}
                      />
                    )}
                    rules={{
                      required: translate('CaptionResource.Required'),
                      pattern: {
                        value: RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/),
                        message: translate('Login.Message.Error_PasswordStrength'),
                      },
                    }}
                  />
                  <div className="errors">
                    {errors?.password && <span dangerouslySetInnerHTML={{ __html: errors?.password.message ?? '' }}></span>}
                  </div>
                </div>
                <div className="row">
                  <Controller
                    name="passwordRepeat"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        type="password"
                        label={translate('Login.PasswordRepeat')}
                        autoComplete="off"
                        canRevealPassword
                        revealPasswordAriaLabel="Show password"
                        {...(field as any)}
                      />
                    )}
                    rules={{
                      validate: (val: string) => {
                        if (watch('password') !== val) {
                          return translate('Login.MsgNotSamePass');
                        }
                      },
                    }}
                  />
                  <div className="errors">{errors?.passwordRepeat && <span>{errors?.passwordRepeat.message}</span>}</div>
                </div>
                <div className="row">
                  <Controller
                    name="passwordHint"
                    control={control}
                    render={({ field }) => <TextField label={translate('Login.PasswordHint')} {...(field as any)} />}
                    rules={{
                      maxLength: { value: 4000, message: translate('CaptionResource.OverMaxLength') },
                    }}
                  />
                  <div className="errors">{errors?.passwordHint && <span>{errors?.passwordHint.message}</span>}</div>
                </div>
                <PrimaryButton className="btn" text={translate('Login.cmdContinue')} onClick={submit} />
              </form>
            </Stack>
          ) : (
            <Stack>
              <div></div>
              <Text variant="medium">{translate('Login.AccountCreated')}</Text>
              <PrimaryButton className="btn" text={translate('Login.cmdReturnLogin')} onClick={backtoLogin} />
            </Stack>
          )}
        </Stack>
      )}
    </>
  );
}
