import { Breadcrumb, IBreadcrumbItem, Icon } from "@fluentui/react";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UpdateImageContainer } from "../../../components/UpdateImageContainer/UpdateImageContainer";
import { DialogTypeEnum } from "../../../core/constants/enums/dialog-type.enum";
import { RouteConstants } from "../../../core/constants/routes.constants";
import { updateAvatarPicture } from "../../../services/account.service";
import { showErrorDialog } from "../../../services/dialog.service";
import { appDispatch } from "../../../states/store";
import { UserActions, userImageUrlSelector } from "../../../states/user/userSlice";
import "./Picture.scss";

export function Picture() {
    const navigate = useNavigate();
    const [translate] = useTranslation();
    const dispatch = appDispatch;

    const userImageUrl = useSelector(userImageUrlSelector);

    const navigateToInformationPage = useCallback(() => {
        navigate(`/${RouteConstants.Information.Root}`);
    }, [navigate])

    const errorUpdateImageContainer = useCallback((message: string) => {
        showErrorDialog(message, "", DialogTypeEnum.Warning);
    }, [])

    const breadcrumbItems = useMemo(() => {
        return [
            { text: translate("CaptionResource.YourInfo"), key: 'YourInfo', onClick: () => navigateToInformationPage() },
            { text: translate("CaptionResource.ChangePicture"), key: 'ChangePicture', isCurrentItem: true }
        ] as IBreadcrumbItem[]
    }, [translate, navigateToInformationPage])

    const saveClick = async (data: any) => {
        try {
            const request = { imageDataUrl: data };
            const res = await updateAvatarPicture(request);
            dispatch(UserActions.updateUserPicture(res));
            navigate(`/${RouteConstants.Information.Root}`);
        }
        catch (err: any) {
            showErrorDialog(err?.messageCaption, err?.message);
        }
    }

    return (
        <>
            <UpdateImageContainer
                breadcrumbChildren={<Breadcrumb items={breadcrumbItems} />}
                acceptFiles="image/*"
                title={translate("CaptionResource.ChangePicture")}
                imageUrl={userImageUrl}
                description={translate("CaptionResource.ChangePictureDescription")}
                addPhotoText={translate("CaptionResource.AddPhoto")}
                removePhotoText={translate("CaptionResource.RemovePhoto")}
                saveOnChange={saveClick}
                cancelOnChange={navigateToInformationPage}
                errorOnChange={errorUpdateImageContainer}
                nonePicture={
                    <div className="none-avatar">
                        <Icon iconName="Contact"></Icon>
                    </div>
                }>
            </UpdateImageContainer>
        </>
    )
}