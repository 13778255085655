export const RouteConstants = {
    Overview: 'overview',
    Information: {
        Root: 'informations',
        Picture: 'pictures',
        Signature: 'signatures',
    },
    Organization: 'organizations',
    ExpressPortal: 'express-portals',

    Login: 'login',
    AuthCallBack: 'auth-callback',
    Logout: 'logout',
    CreateAccount: 'create-account',
    ResetPassword: 'reset-password',
    LoginOnixExpress: 'login-onix-express',
    CreateAccountOnixExpress: 'create-account-onix-express',
    ResetPasswordOnixExpress: 'reset-password-onix-express',

    Others: '*'
}