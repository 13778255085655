import { ActionButton, Icon, MessageBarType, Text, Stack } from '@fluentui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { DialogTypeEnum } from '../../core/constants/enums/dialog-type.enum';
import { RouteConstants } from '../../core/constants/routes.constants';
import { Organization } from '../../models/organization.model';
import { sendResetPasswordEmail } from '../../services/account.service';
import { showErrorDialog } from '../../services/dialog.service';
import { showHomeLayoutMessageBar } from '../../services/message-bar.service';
import { getMemberHomeOrganization } from '../../services/organization.service';
import { userSelector } from '../../states/user/userSlice';
import AppList from './AppList/AppList';
import ExpressList from './ExpressList/ExpressList';
import './Overview.scss';

export default function Overview() {
  const [translate] = useTranslation();
  const navigate = useNavigate();
  const [homeOrg, setHomeOrg] = useState<Organization | null | undefined>(undefined);
  const userAccount = useSelector(userSelector);

  const yourInfoClick = () => {
    navigate(`/${RouteConstants.Information.Root}`);
  };

  const organizationClick = () => {
    navigate(`/${RouteConstants.Organization}`);
  };

  const passwordClick = async () => {
    if (userAccount.ssoEnforcement) {
      showErrorDialog(
        '',
        translate('Home.CannotChangeManagedAccountPassword', { 0: userAccount.companyName }),
        DialogTypeEnum.CustomTitle,
        translate('Home.CannotChangePassword')
      );
      return;
    }
    const result = await sendResetPasswordEmail();
    if (result?.success) {
      showHomeLayoutMessageBar(translate('CaptionResource.ResetPasswordSentMessage'), {
        messageBarType: MessageBarType.success,
        dismissButtonAriaLabel: 'Close',
      });
    }
  };

  useEffect(() => {
    const getData = async () => {
      const memberHomeOrganization = await getMemberHomeOrganization();
      setHomeOrg(memberHomeOrganization);
    };
    getData();
  }, []);

  return (
    <Stack className="overview-container">
      <Text variant="xxLarge">{translate('Home.WelcomeToOnixAccount')}</Text>
      {homeOrg !== undefined && (homeOrg !== null ? <AppList></AppList> : <ExpressList></ExpressList>)}
      <Text variant="xLarge" className="action-title">
        {translate('Home.QuickLinks')}
      </Text>
      <div className="main-sections">
        <div className="your-info">
          <span className="title">{translate('CaptionResource.YourInfo')}</span>
          <Icon className="overview-icon" iconName="EditContact"></Icon>
          <span>{translate('CaptionResource.YourInfoIntro')}</span>
          <ActionButton onClick={yourInfoClick}>
            <div className="content-button">
              {translate('CaptionResource.UpdateInfo')}
              <Icon iconName="ChevronRight"></Icon>
            </div>
          </ActionButton>
        </div>
        <div className="others">
          <div>
            <span className="title">{translate('CaptionResource.Organization')}</span>
            <Icon className="overview-icon" iconName="CompanyDirectory"></Icon>
            <span>{translate('CaptionResource.OrganizationIntro')}</span>
            <ActionButton onClick={organizationClick}>
              <div className="content-button">
                {translate('CaptionResource.ManageOrganization')}
                <Icon iconName="ChevronRight"></Icon>
              </div>
            </ActionButton>
          </div>
          <div>
            <span className="title">{translate('CaptionResource.Password')}</span>
            <Icon className="overview-icon" iconName="PasswordField"></Icon>
            <span>{translate('CaptionResource.PasswordIntro')}</span>
            <ActionButton onClick={passwordClick}>
              <div className="content-button">
                {translate('CaptionResource.ChangePassword')}
                <Icon iconName="ChevronRight"></Icon>
              </div>
            </ActionButton>
          </div>
        </div>
      </div>
    </Stack>
  );
}
