import { ActionButton, Label } from '@fluentui/react';
import { Card, Modal } from '@onix/common';
import { SupportedLanguageConstants } from '@onix/common/src/constants/language.constants';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { userSelector } from '../../../states/user/userSlice';
import { ProfileInfo } from './ProfileInfo/ProfileInfo';
import './ProfileSection.scss';

export function ProfileSection() {
  const user = useSelector(userSelector);
  const [translate] = useTranslation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <div className="profile-section">
        <Card>
          <div className="card-title-content">
            <span className="card-title">{translate('CaptionResource.ProfileInfo')}</span>
            <div className="right-controls">
              <ActionButton className="text-button" onClick={() => setIsModalOpen(true)}>
                {translate('CaptionResource.EditProfileInfo')}
              </ActionButton>
            </div>
          </div>
        </Card>
        <Card>
          <div className="field">
            <Label>{translate('CaptionResource.Name')}</Label>
            <span>{user.fullName}</span>
          </div>
        </Card>
        <Card>
          <div className="field email">
            <Label>{translate('CaptionResource.Email')}</Label>
            <span>{user.email}</span>
          </div>
        </Card>
        <Card>
          <div className="field">
            <Label>{translate('CaptionResource.MobilePhoneMA')}</Label>
            <span>{user.userCellPhone}</span>
          </div>
        </Card>
        <Card>
          <div className="field">
            <Label>{translate('CaptionResource.PreferredLanguage')}</Label>
            <span>{SupportedLanguageConstants.find((a) => a.id === user.languageId)?.name}</span>
          </div>
        </Card>
      </div>
      <Modal
        isModalOpen={isModalOpen}
        props={{ isBlocking: true }}
        onDismiss={() => setIsModalOpen(false)}
        title={translate('CaptionResource.EditProfileInfo')}
        description={translate('CaptionResource.EditProfileInfoDescriptionMA')}
        style={{ width: '600px', height: 'auto' }}
      >
        <ProfileInfo openModalChange={setIsModalOpen}></ProfileInfo>
      </Modal>
    </>
  );
}
