import "./Organization.scss";
import { useTranslation } from "react-i18next"
import { HomeOrganization } from "./HomeOrganization/HomeOrganization";
import { OtherOrganization } from "./OtherOrganization/OtherOrganization";
import { OpenInvitations } from "./OpenInvitations/OpenInvitations";

export default function Organization() {
    const [translate] = useTranslation();

    return (
        <div className="organization-container">
            <span className="title-page">
                {translate('CaptionResource.Organization')}
            </span>
            <div className="sections">
                <HomeOrganization></HomeOrganization>
                <OtherOrganization></OtherOrganization>
                <OpenInvitations></OpenInvitations>
            </div>
        </div>
    )
}