import './Signature.scss';
import { Breadcrumb, IBreadcrumbItem, Icon, Link } from '@fluentui/react';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { UpdateImageContainer } from '../../../components/UpdateImageContainer/UpdateImageContainer';
import { DialogTypeEnum } from '../../../core/constants/enums/dialog-type.enum';
import { RouteConstants } from '../../../core/constants/routes.constants';
import { updateSignaturePicture } from '../../../services/account.service';
import { showErrorDialog } from '../../../services/dialog.service';
import { appDispatch } from '../../../states/store';
import { signaturePictureSelector, UserActions } from '../../../states/user/userSlice';

export function Signature() {
  const dispatch = appDispatch;
  const navigate = useNavigate();
  const [translate] = useTranslation();

  const signaturePicture = useSelector(signaturePictureSelector);

  const navigateToInformationPage = useCallback(() => {
    navigate(`/${RouteConstants.Information.Root}`);
  }, [navigate]);

  const errorUpdateImageContainer = useCallback((message: string) => {
    showErrorDialog(message, '', DialogTypeEnum.Warning);
  }, []);

  const breadcrumbItems = useMemo(() => {
    return [
      { text: translate('CaptionResource.YourInfo'), key: 'YourInfo', onClick: () => navigateToInformationPage() },
      { text: translate('CaptionResource.ChangeSignature'), key: 'ChangePicture', isCurrentItem: true },
    ] as IBreadcrumbItem[];
  }, [translate, navigateToInformationPage]);

  const saveClick = async (data: any) => {
    try {
      const request = { imageDataUrl: data };
      const res = await updateSignaturePicture(request);
      dispatch(UserActions.updateSignaturePicture(res));
      navigate(`/${RouteConstants.Information.Root}`);
    } catch (err: any) {
      showErrorDialog(err?.messageCaption, err?.message, DialogTypeEnum.Error);
    }
  };

  return (
    <>
      <UpdateImageContainer
        breadcrumbChildren={<Breadcrumb items={breadcrumbItems} />}
        acceptFiles="image/*"
        aspect={3}
        title={translate('CaptionResource.ChangeSignature')}
        imageUrl={signaturePicture}
        description={
          <>
            {translate('CaptionResource.ChangeSignatureDescription') + ' '}
            <Link target="_blank" href={translate('CaptionResource.ChangeSignatureLearnMoreLink')} underline>
              {translate('CommonResource.LearnMore')}
            </Link>
          </>
        }
        addPhotoText={translate('CaptionResource.AddSignature')}
        removePhotoText={translate('CaptionResource.RemoveSignature')}
        saveOnChange={saveClick}
        cancelOnChange={navigateToInformationPage}
        errorOnChange={errorUpdateImageContainer}
        nonePicture={
          <div className="none-signature">
            <Icon iconName="PictureFill"></Icon>
          </div>
        }
      ></UpdateImageContainer>
    </>
  );
}
