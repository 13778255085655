import { WebStorageStateStore } from 'oidc-client';
import { ApplicationSettingInterface } from '../models/application-setting.model';

export const environment: ApplicationSettingInterface = {
  name: 'Beta',
  clientApplicationId: 17,
  apiUrl: 'https://onix-my-account-api-beta-v2.azurewebsites.net',
  identityApiUrl: 'https://login-beta.onix.com',
  enableBanner: false,
  oidcSetting: {
    authority: 'https://login-beta.onix.com',
    client_id: '392w5fa6RPgYCL3A6jyij8kvqoQbg43gbe',
    redirect_uri: 'https://myaccount-beta.onix.com/auth-callback',
    post_logout_redirect_uri: 'https://myaccount-beta.onix.com/login',
    response_type: 'code',
    scope: 'openid email onixmyaccount offline_access',
    filterProtocolClaims: false,
    loadUserInfo: false,
    automaticSilentRenew: false,
    //checkSessionInterval: 2000,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
    monitorSession: true,
    includeIdTokenInSilentRenew: false,
    revokeAccessTokenOnSignout: true,
  },
};
