import { ActionButton, Icon, Link, MessageBarType } from '@fluentui/react';
import { Card } from '@onix/common';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Section } from '../../../components/Section/Section';
import { DialogTypeEnum } from '../../../core/constants/enums/dialog-type.enum';
import { Organization } from '../../../models/organization.model';
import { getExpressCompanyInvitations } from '../../../services/account.service';
import { showConfirmDialog, showErrorDialogWithResponse } from '../../../services/dialog.service';
import { showHomeLayoutMessageBar } from '../../../services/message-bar.service';
import { getExpressOrganization, leaveOrganization } from '../../../services/organization.service';
import { yourAccessesSelector } from '../../../states/express/expressSlice';

export function YourAccess() {
  const [translate] = useTranslation();
  const organizations = useSelector(yourAccessesSelector);

  useEffect(() => {
    getExpressOrganization();
  }, []);

  const leave = useCallback(async (organization: Organization) => {
    try {
      await leaveOrganization(organization.contactId, organization.companyId);
      showHomeLayoutMessageBar(
        <>{translate('CaptionResource.LeftOnixExpressMessage', { 0: organization?.companyOwnerName, 1: organization?.companyName })}</>,
        { messageBarType: MessageBarType.success, dismissButtonAriaLabel: 'Close' }
      );
      getExpressOrganization();
      getExpressCompanyInvitations();
    } catch (err) {
      showErrorDialogWithResponse(err);
    }
  }, []);

  const openConfirmLeaveDialog = (organization: Organization) => {
    showConfirmDialog({
      dialogContentProps: {
        title: <>{translate('CaptionResource.LeaveOnixExpress')}</>,
        showCloseButton: true,
        subText: translate('CaptionResource.LeaveOnixExpressDescription', {
          0: organization?.companyOwnerName,
          1: organization?.companyName,
        }),
      },
      onAccept: () => leave(organization),
    });
  };

  return (
    <Section
      className="your-access-section"
      title={translate('CaptionResource.ExpressYourAccess')}
      description={
        <>
          <span>{translate('CaptionResource.ExpressYourAccessDescription') + ' '}</span>
          <Link target="_blank" href={translate('CaptionResource.ExpressYourAccessLearnMore')} underline>
            {translate('CommonResource.LearnMore')}
          </Link>
        </>
      }
    >
      <>
        {organizations.map((yourAccess) => {
          return (
            <Card key={yourAccess.contactId}>
              <div className="card-content">
                <Icon iconName="Documentation" styles={{ root: { fontSize: '24px' } }} />
                <div className="details">
                  <span className="name">
                    {yourAccess?.companyOwnerName} - {yourAccess?.companyName}
                  </span>
                </div>
                <div className="right-controls">
                  <ActionButton onClick={() => openConfirmLeaveDialog(yourAccess)} iconProps={{ iconName: 'Leave' }}>
                    {translate('CaptionResource.Leave')}
                  </ActionButton>
                </div>
              </div>
            </Card>
          );
        })}
        {organizations.length === 0 && (
          <Card>
            <div className="card-content">
              <Icon iconName="Documentation" styles={{ root: { fontSize: '24px' } }} />
              <div className="details">
                <span className="name text-grey-130">{translate('CaptionResource.NotBelongAnyOnixExpress')}</span>
              </div>
            </div>
          </Card>
        )}
      </>
    </Section>
  );
}
