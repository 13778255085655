import { PartialTheme, PrimaryButton, Text, TextField, ThemeProvider } from '@fluentui/react';
import { LanguageHelper } from '@onix/common';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { RouteConstants } from '../../core/constants/routes.constants';
import { getInformationToResetPasswordOnixExpress, resetPasswordOnixExpress } from '../../services/account.service';
import { showErrorDialogWithResponse } from '../../services/dialog.service';
import './ResetPasswordOnixExpress.scss';

const defaultFormValue = {
  password: '',
  confirmPassword: '',
  passwordHint: '',
  loginUrl: '',
  companyExpressSetupInfo: {
    primaryColor: '',
    companyLogoImageBase64: '',
    primaryTextColor: '',
  },
};

export default function ResetPasswordOnixExpress() {
  const [translate, { isInitialized, changeLanguage }] = useTranslation();
  const [customTheme, setcCstomTheme] = useState<PartialTheme>();
  const navigate = useNavigate();

  const {
    handleSubmit,
    control,
    getValues,
    formState: { errors, touchedFields },
    trigger,
  } = useForm({ defaultValues: defaultFormValue, mode: 'onTouched' });

  const [searchParams] = useSearchParams();

  const [userAccount, setUserAccount] = useState<any | null>(null);
  const [key, setKey] = useState<string | null>(null);
  const [isSuccess, setSuccess] = useState(false);
  const [returnUrl, setReturnUrl] = useState<string>('');

  useEffect(() => {
    async function getSuggestionData() {
      try {
        const request = { param: searchParams.get('param') };
        const userAccount = await getInformationToResetPasswordOnixExpress(request);
        if (!userAccount.companyExpressSetupInfo) {
          window.location.href = userAccount.loginUrl;
        }
        const customTheme: PartialTheme = {
          palette: {
            themePrimary: '#8D3A8E',
            themeDarkAlt: '#8D3A8E',
          },
        };
        setcCstomTheme(customTheme);
        setUserAccount(userAccount);
        updateLanguage(userAccount);
        setKey(userAccount?.key);
        setReturnUrl(userAccount.loginUrl);
      } catch {
        navigate(`/${RouteConstants.Login}`);
      }
    }
    getSuggestionData();
  }, []);

  useEffect(() => {
    updateLanguage();
  }, [isInitialized]);

  const updateLanguage = (userAccountData = userAccount) => {
    if (userAccountData) {
      const language = LanguageHelper.getLanguageCode(userAccountData.languageId);
      changeLanguage(language);
    }
  };

  const submitForm = async () => {
    if (!key) {
      return;
    }
    try {
      const request = {
        key,
        email: userAccount?.email,
        ...getValues(),
      };
      await resetPasswordOnixExpress(request);
      setSuccess(true);
    } catch (err) {
      showErrorDialogWithResponse(err);
    }
  };

  const backToLoginPage = () => {
    window.location.href = userAccount.loginUrl;
  };

  return (
    userAccount &&
    userAccount.companyExpressSetupInfo && (
      <div className="reset-password-onix-express primary-box">
        <ThemeProvider theme={customTheme}>
          <div className="reset-password-container">
            <div
              style={{
                color: userAccount?.companyExpressSetupInfo.primaryTextColor,
                backgroundColor: userAccount?.companyExpressSetupInfo.primaryColor,
              }}
              className="reset-password-header"
            >
              <img
                className="onix-logo"
                src={`data:image/jpeg;base64,${userAccount?.companyExpressSetupInfo.companyLogoImageBase64}`}
                alt="logo"
              ></img>
              <Text style={{ color: userAccount?.companyExpressSetupInfo.primaryTextColor }} variant="xxLarge">
                {translate('Login.cmdResetPassword')}
              </Text>
              <Text style={{ color: userAccount?.companyExpressSetupInfo.primaryTextColor }} variant="medium" className="email">
                {translate('CommonResource.Email')}: {userAccount?.email}
              </Text>
            </div>
            <div className="reset-password-body">
              {isSuccess ? (
                <>
                  <Text variant="medium">{translate('CaptionResource.ResetPasswordSuccessfully')}</Text>
                  <PrimaryButton
                    style={{
                      boxShadow: '0 0 15px 0 rgba(0,0,0,.18)',
                      border: '0px',
                      color: userAccount?.companyExpressSetupInfo.primaryTextColor,
                      backgroundColor: userAccount?.companyExpressSetupInfo.primaryColor,
                      width: '100%',
                      marginTop: '1rem',
                    }}
                    onClick={backToLoginPage}
                  >
                    {translate('Login.cmdReturnLogin')}
                  </PrimaryButton>
                </>
              ) : (
                <>
                  {returnUrl && <Text variant="medium">{translate('Login.ResetPasswordText')}</Text>}
                  <form className="form-default" onSubmit={handleSubmit(submitForm)}>
                    <div className="row">
                      <div className="controls">
                        <Controller
                          name="password"
                          control={control}
                          rules={{
                            required: translate('CaptionResource.Required'),
                            pattern: {
                              value: RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/),
                              message: translate('Login.Message.Error_PasswordStrength'),
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              label={translate('Login.NewPassword')}
                              {...field}
                              autoComplete="off"
                              canRevealPassword
                              revealPasswordAriaLabel="Show password"
                              type="password"
                              onChange={(e: any) => {
                                field.onChange(e);
                                if (touchedFields?.confirmPassword) {
                                  trigger('confirmPassword');
                                }
                                if (touchedFields?.passwordHint) {
                                  trigger('passwordHint');
                                }
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="errors">
                        {errors?.password && <span dangerouslySetInnerHTML={{ __html: errors?.password?.message ?? '' }}></span>}
                      </div>
                    </div>
                    <div className="row">
                      <div className="controls">
                        <Controller
                          name="confirmPassword"
                          control={control}
                          rules={{ validate: (value) => value === getValues('password') }}
                          render={({ field }) => (
                            <TextField
                              label={translate('Login.PasswordRepeat')}
                              {...(field as any)}
                              autoComplete="off"
                              canRevealPassword
                              revealPasswordAriaLabel="Show password"
                              type="password"
                            />
                          )}
                        />
                      </div>
                      <div className="errors">{errors?.confirmPassword && <span>{translate('Login.MsgNotSamePass')}</span>}</div>
                    </div>

                    <div className="row">
                      <div className="controls">
                        <Controller
                          name="passwordHint"
                          control={control}
                          rules={{ validate: (value) => value !== getValues('password') || value === '', deps: 'password' }}
                          render={({ field }) => <TextField {...field} label={translate('Login.PasswordHint')} />}
                        />
                      </div>
                      <div className="errors">{errors?.passwordHint && <span>{translate('Login.Message.Error_PasswordHint')}</span>}</div>
                    </div>

                    <div className="row">
                      <PrimaryButton
                        type="submit"
                        style={{
                          boxShadow: '0 0 15px 0 rgba(0,0,0,.18)',
                          border: '0px',
                          color: userAccount.companyExpressSetupInfo.primaryTextColor,
                          backgroundColor: userAccount?.companyExpressSetupInfo.primaryColor,
                          width: '100%',
                          marginTop: '1rem',
                        }}
                        text={translate('Login.cmdContinue')}
                      />
                    </div>
                  </form>
                </>
              )}
              <div className="copyright">
                <span>{translate('CommonResource.PoweredBy')} </span>
                <a target="_blank" href="https://www.onix.com/" rel="noreferrer">
                  Onix Work
                </a>
              </div>
            </div>
          </div>
        </ThemeProvider>
      </div>
    )
  );
}
