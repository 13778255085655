import { Navigate, Outlet } from "react-router-dom";
import { hasToken } from "../../services/authentication.service";

export default function Authentication() {
    const hasTokenCurrently = hasToken();
    
    if (hasTokenCurrently) {
        return (
            <Outlet></Outlet>
        )
    }

    return (
        <Navigate to="/login" replace></Navigate>
    )
}