import { MutableRefObject, useEffect } from "react";

export const useOutsideClick = <T extends Array<MutableRefObject<any>>>(ref: T, callback: () => void): void => {

  useEffect(() => {
    const handler = (event: MouseEvent): void => {

      if (!ref || ref.length === 0) {
        return;
      }

      const node = ref.find((x) => x?.current?.contains(event?.target as Node))
      if (!node) {
        callback()
      }
    }

    window.addEventListener('mousedown', handler)

    return () => {
      window.removeEventListener('mousedown', handler)
    }
    
  }, [ref, callback])
  
}