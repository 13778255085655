import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ContactUserFilter } from "../../models/contact-user-filter.model";
import { Organization } from "../../models/organization.model";
import { RootState } from "../store";

type ExpressState = {
    yourAccesses: Organization[] | [];
    invitations: ContactUserFilter[] | [];
}

const initialState: ExpressState = {
    yourAccesses: [],
    invitations: []
};

const ExpressSlice = createSlice({
    name: 'express',
    initialState: initialState,
    reducers: {
        updateYourAccesses: (state, action: PayloadAction<Organization[]>) => {
            let accesses: Organization[] | [] = [];
            if (action.payload) {
                accesses = action.payload;
            }
            state.yourAccesses = accesses;
        },
        updateInvitations: (state, action: PayloadAction<ContactUserFilter[]>) => {
            state.invitations = action.payload;
        }
    }
});

export const ExpressActions = ExpressSlice.actions;

export const ExpressReducer = ExpressSlice.reducer;


// SELECTOR 
const selectSelf = (state: RootState) => state.express;
export const yourAccessesSelector = createSelector(selectSelf, state => state.yourAccesses);
export const expressInvitationsSelector = createSelector(selectSelf, state => state.invitations);
