import { Link, MessageBar } from '@fluentui/react';
import { LanguageHelper } from '@onix/common';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { UserAccount } from '../../models/user-account.model';
import { getInformation } from '../../services/account.service';
import { getUser } from '../../services/authentication.service';
import { homeLayoutMessageBarSelector } from '../../states/app/appSlice';
import { appDispatch } from '../../states/store';
import { UserActions } from '../../states/user/userSlice';
import EnvironmentBanner from './EnvironmentBanner/EnvironmentBanner';
import Header from './Header/Header';
import './HomeLayout.scss';
import LeftMenu from './LeftMenu/LeftMenu';

export default function HomeLayout() {
  const { i18n } = useTranslation();
  const [translate] = useTranslation();
  const dispatch = appDispatch;
  const messageBars = useSelector(homeLayoutMessageBarSelector);
  const [ssoEnforcement, setSsoEnforcement] = useState(false);
  const [companyName, setCompanyName] = useState('');

  useEffect(() => {
    const loadContact = async () => {
      const userAccount = (await getInformation()) as UserAccount;
      setSsoEnforcement(userAccount.ssoEnforcement);
      setCompanyName(userAccount.companyName);
      const user = await getUser();
      userAccount.email = user ? user.profile.sub : '';
      dispatch(UserActions.updateUser(userAccount));
      if (userAccount.languageId != null) {
        const languageCode = LanguageHelper.getLanguageCode(userAccount.languageId);
        i18n.changeLanguage(languageCode);
      }
    };
    loadContact();
  }, []);

  return (
    <div className="home-layout">
      <Header></Header>
      <div className="home-layout-content">
        <LeftMenu></LeftMenu>
        <div className="home-layout-container">
          <div className="message-bar">
            {ssoEnforcement && (
              <MessageBar>
                {translate('Home.ManagedAccountExplainText', { 0: companyName })}
                <Link target="_blank" href={translate('Home.ManagedAccountLearnMoreLink')} underline>
                  {translate('CommonResource.LearnMore')}
                </Link>
              </MessageBar>
            )}
            {messageBars.map((messageBar) => (
              <MessageBar key={messageBar.id} {...messageBar.props}>
                {messageBar.content}
              </MessageBar>
            ))}
          </div>
          <Outlet />
        </div>
      </div>
      <EnvironmentBanner></EnvironmentBanner>
    </div>
  );
}
