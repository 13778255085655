import { ActionButton, Icon, Link, MessageBarType } from '@fluentui/react';
import { Card } from '@onix/common';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Section } from '../../../components/Section/Section';
import { DialogTypeEnum } from '../../../core/constants/enums/dialog-type.enum';
import { getMemberCompanyInvitations } from '../../../services/account.service';
import { refreshToken } from '../../../services/authentication.service';
import { showConfirmDialog, showErrorDialogWithResponse } from '../../../services/dialog.service';
import { showHomeLayoutMessageBar } from '../../../services/message-bar.service';
import { getMemberHomeOrganization, getMemberOrganization, leaveOrganization } from '../../../services/organization.service';
import { homeOrganizationSelector, OrganizationActions } from '../../../states/organization/organizationSlice';
import { appDispatch } from '../../../states/store';
import './HomeOrganization.scss';

export function HomeOrganization() {
  const [translate] = useTranslation();
  const dispatch = appDispatch;
  const homeOrganization = useSelector(homeOrganizationSelector);

  const getHomeOrg = useCallback(async () => {
    await getMemberHomeOrganization();
  }, []);

  useEffect(() => {
    getHomeOrg();
  }, []);

  const leave = useCallback(async () => {
    if (!homeOrganization) {
      return;
    }
    try {
      await leaveOrganization(homeOrganization.contactId, homeOrganization.companyId);
      showHomeLayoutMessageBar(<>{translate('CaptionResource.LeftOrganizationMessage', { 0: homeOrganization.companyName })}</>, {
        messageBarType: MessageBarType.success,
        dismissButtonAriaLabel: 'Close',
      });
      getHomeOrg();
      getMemberCompanyInvitations({});

      const res = await getMemberOrganization({});
      dispatch(OrganizationActions.updateOtherOrg(res.items));
      dispatch(OrganizationActions.updateOtherControls({ text: '' }));
      await refreshToken();
    } catch (err) {
      showErrorDialogWithResponse(err);
    }
  }, [homeOrganization]);

  const openConfirmLeaveDialog = () => {
    showConfirmDialog({
      dialogContentProps: {
        title: <>{translate('CaptionResource.LeaveOrganization')}</>,
        showCloseButton: true,
        subText: translate('CaptionResource.ConfirmationLeaveOrganization', { 0: homeOrganization?.companyName ?? '' }),
      },
      onAccept: leave,
    });
  };

  return (
    <Section
      className="home-organization-section"
      title={translate('CaptionResource.HomeOrganization')}
      description={
        <>
          <span>{translate('CaptionResource.HomeOrganizationDescription') + ' '}</span>
          <Link target="_blank" href={translate('CaptionResource.HomeOrgLearnMore')} underline>
            {translate('CommonResource.LearnMore')}
          </Link>
        </>
      }
    >
      <Card>
        <div className="card-content">
          <Icon iconName="Home" styles={{ root: { fontSize: '24px' } }} />
          <div className="details">
            {homeOrganization && <span className="name">{homeOrganization?.companyName}</span>}
            {!homeOrganization && <span className="position text-grey-130">{translate('CaptionResource.NotBelongAnyOrganization')}</span>}
          </div>
          {homeOrganization && (
            <div className="right-controls">
              <ActionButton onClick={openConfirmLeaveDialog} iconProps={{ iconName: 'Leave' }}>
                {translate('CaptionResource.Leave')}
              </ActionButton>
            </div>
          )}
        </div>
      </Card>
    </Section>
  );
}
