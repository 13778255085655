import { WebStorageStateStore } from 'oidc-client';
import { ApplicationSettingInterface } from '../models/application-setting.model';

export const environment: ApplicationSettingInterface = {
  name: 'Dev',
  clientApplicationId: 17,
  apiUrl: 'https://localhost/api-my-account-local',
  identityApiUrl: 'https://localhost/identity-local',
  enableBanner: true,
  oidcSetting: {
    authority: 'https://localhost/identity-local',
    client_id: '392w5fa6RPgYCL3A6jyij8kvqoQbg43gde',
    redirect_uri: 'http://localhost:3000/auth-callback',
    post_logout_redirect_uri: 'http://localhost:3000/login',
    response_type: 'code',
    scope: 'openid email onixmyaccount offline_access',
    filterProtocolClaims: false,
    loadUserInfo: false,
    automaticSilentRenew: false,
    //checkSessionInterval: 2000,
    userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
    monitorSession: false, //only work normally via https, so turn off for development mode
    includeIdTokenInSilentRenew: false,
    revokeAccessTokenOnSignout: true,
  },
};
