import "./Header.scss";
import { useRef, useState } from "react";
import { ActionButton } from "@fluentui/react";
import { AccountAction } from "./AccountAction/AccountAction";
import { useOutsideClick } from "../../../hooks/useOutsideClick";
import OnixAccountLogo from "../../../assets/images/onix-account-logo.svg";
import OnixAccountLogoWhite from "../../../assets/images/onix-account-logo-white.svg";
import { Link, Route, Routes, BrowserRouter as Router } from "react-router-dom";

export default function Header() {
    const [isWaffleOpen, setIsWaffleOpen] = useState(false);
    const waffleMenuRef = useRef(null);

    useOutsideClick([waffleMenuRef], () => {
        setIsWaffleOpen(false);
    })

    const waffleClicked = () => {
        const value = !isWaffleOpen;
        setIsWaffleOpen(value);
    }
    
    return (
        <div className="header">
            <div className="left-position">  
                <div> 
                    <Link to="/overview">
                        <img alt="onix-logo" src={OnixAccountLogo} className="desktop"></img>
                    </Link> 
                </div>

                <div> 
                    <Link to="/overview">
                    <img alt="onix-logo" src={OnixAccountLogoWhite} className="mobile"></img>
                    </Link> 
                </div>

                <div ref={waffleMenuRef} className={'waffle-menu' + (isWaffleOpen? ' waffle-menu-open': '')}>
                    <ActionButton allowDisabledFocus iconProps={{iconName:"Waffle"}} onClick={waffleClicked} className="waffle-button">
                    </ActionButton>
                </div>
                <div className="divider-right"></div>
            </div>

            <div className="right-position">
                <AccountAction></AccountAction>
            </div>
        </div>
    )
}