import { HTMLAttributes } from "react";
import "./Card.scss";

interface Props extends HTMLAttributes<HTMLElement> {
    children?: any;
}

export default function Card(props: Props) {
    return (
        <div {...props} className={`card-container ${props?.className}`}>
            {props?.children}
        </div>
    )
}