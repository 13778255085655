import { DirectionalHint, IContextualMenuItem, IContextualMenuProps } from "@fluentui/react";
import { useMemo } from "react";

export function useContextualMenu(
    menuItems: IContextualMenuItem[],
    contextualMenuProps?: IContextualMenuProps
    ) {
    
    const menuProps: IContextualMenuProps = useMemo(
        () => ({
            items: menuItems,
            directionalHint: DirectionalHint.bottomRightEdge,
            gapSpace: 0,
            isBeakVisible: true,
            styles:{
                root:{
                    padding: '10px 10px',
                    boxSizing: 'border-box',
                    gap: '7px',
                    display: 'flex',
                    flexDirection: 'column'
                }
            },
            ...contextualMenuProps
        }),
        [menuItems, contextualMenuProps],
    );
    
    return {
        menuProps
    };
}