import { ActionButton, Icon, Link, MessageBarType } from '@fluentui/react';
import { Card } from '@onix/common';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Section } from '../../../components/Section/Section';
import { DialogTypeEnum } from '../../../core/constants/enums/dialog-type.enum';
import { ContactUserFilter } from '../../../models/contact-user-filter.model';
import { createExpressUserByInvitation, getExpressCompanyInvitations } from '../../../services/account.service';
import { showErrorDialogWithResponse } from '../../../services/dialog.service';
import { showHomeLayoutMessageBar } from '../../../services/message-bar.service';
import { getExpressOrganization } from '../../../services/organization.service';
import { expressInvitationsSelector } from '../../../states/express/expressSlice';
import { domainEmailSelector } from '../../../states/user/userSlice';

export function OpenInvitations() {
  const [translate] = useTranslation();
  const invitations = useSelector(expressInvitationsSelector);
  const domainEmail = useSelector(domainEmailSelector);

  useEffect(() => {
    getExpressCompanyInvitations();
  }, []);

  const createUser = useCallback(async (invitation: ContactUserFilter) => {
    try {
      await createExpressUserByInvitation(invitation.contactUserFilterId);
      showHomeLayoutMessageBar(
        <>
          {translate('CaptionResource.CreateExpressUserSuccessfully', {
            0: invitation?.companyExpertDescription,
            1: invitation?.companyDescription,
          })}
        </>,
        { messageBarType: MessageBarType.success, dismissButtonAriaLabel: 'Close' }
      );
      getExpressCompanyInvitations();
      getExpressOrganization();
    } catch (err) {
      showErrorDialogWithResponse(err);
    }
  }, []);

  return (
    <Section
      className="express-invitations"
      title={translate('CaptionResource.OpenInvitations')}
      description={
        <>
          <span dangerouslySetInnerHTML={{ __html: translate('CaptionResource.ExpressOpenInvitationDescription', { 0: domainEmail }) }} />{' '}
          <Link target="_blank" href={translate('CaptionResource.ExpressOpenInvitationLearnMore')} underline>
            {translate('CommonResource.LearnMore')}
          </Link>
        </>
      }
    >
      <>
        {invitations.length > 0 &&
          invitations.map((invitation) => {
            return (
              <Card key={invitation.contactUserFilterId}>
                <div className="card-content" key={invitation.contactUserFilterId}>
                  <Icon iconName="CompanyDirectory" styles={{ root: { fontSize: '24px' } }} />
                  <div className="details">
                    <span className="name">
                      {invitation.companyExpertDescription} - {invitation.companyDescription}
                    </span>
                  </div>
                  <div className="right-controls">
                    <ActionButton onClick={() => createUser(invitation)}>{translate('CaptionResource.CreateUser')}</ActionButton>
                  </div>
                </div>
              </Card>
            );
          })}
        {invitations.length === 0 && (
          <Card>
            <div className="card-content">
              <Icon iconName="CompanyDirectory" styles={{ root: { fontSize: '24px' } }} />
              <div className="details">
                <span className="name text-grey-130">{translate('CaptionResource.OpenInvitationEmpty')}</span>
              </div>
            </div>
          </Card>
        )}
      </>
    </Section>
  );
}
